import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon1 from "asset/images/images/edit-image-1.svg";
import { aboutValidationSchema } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import { InputField } from "forms/hoc/formfield";
import { OutlinedButton } from "component/button";

const AboutOrganisation = () => {
  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleClose = () => {
    setEditing(false);
  };
  const handleUpdateLogin = () => {
    console.log("data");
  };
  const aboutValues = {
    introduction: null,
  };
  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(aboutValues, handleUpdateLogin, aboutValidationSchema);

  return (
    <>
      <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", mt: 3 }}>
        <CardContent
          sx={{ color: "#616B73", fontWeight: 500, fontSize: "14px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={600} fontSize={"18px"} color={"#15171E"}>
              About Organisation
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", color: "#AB5B0B" }}
              onClick={handleEdit}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon1} alt="edit" style={{ paddingLeft: "30px" }} />
              <Typography sx={{ pl: 1, fontWeight: 600, fontSize: "14px" }}>
                Edit
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", my: 2 }}>
            <Divider />
          </Box>
          <Typography sx={{ color: "#616B73" }}>
            R System International is a leading provider of comprehensive IT
            solutions and services, catering to a diverse range of clients
            globally. With a strong focus on innovation, reliability, and
            customer satisfaction, System International has established itself
            as a trusted partner for businesses seeking cutting-edge technology
            solutions.
          </Typography>
          <Typography sx={{ mt: 3, color: "#616B73" }}>
            With offices and operations spanning multiple continents, System
            International has a global footprint, enabling it to deliver
            localised support and services to clients worldwide. The company's
            diverse team of IT professionals brings together a wealth of
            cultural insights and technical expertise to address the unique
            challenges of different markets.
          </Typography>
          <Typography sx={{ mt: 3, color: "#616B73" }}>
            R Systems corporate social responsibility extends to all communities
            in societies where we exist. Our endeavors to make a positive impact
            through our CSR activities are focused on education, health &
            community development.
          </Typography>
        </CardContent>
      </Card>

      <Dialog
        open={editing}
        // onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "50%",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            color: "#000000",
            padding: "20px",
          }}
        >
          Edit About Organisation
        </DialogTitle>
        <Divider sx={{ mb: 1, borderColor: "#000000", width: "100%" }} />
        <DialogContent sx={{ padding: "20px" }}>
          <InputField
            fieldName="Introduction"
            values={values?.introduction || ""}
            name="introduction"
            errors={errors?.introduction}
            handleChange={handleChange}
            isRequired={false}
            id="introduction"
          />
        </DialogContent>
        <DialogActions>
        <OutlinedButton
            size={"small"}
            label={"cancel"}
            isBold={true}
            handleClick={handleClose}
          ></OutlinedButton>
          <Button
            sx={{ fontWeight: 600, fontSize: "18px", borderRadius: "48px",mr:1 }}
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AboutOrganisation;
