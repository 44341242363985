import axios from "axios";
import { IsBaseURL } from "helpers/interceptor";


const getServiceWithParams = (url: string, param: any, isCustom?: boolean ): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);

  return new Promise(function (resolve, reject) {
    axios
      .get(url, {
        params: param,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const getServices = (url: string,isCustom?: boolean): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);
  return new Promise(function (resolve, reject) {
    axios
      .get(url)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const postService = (url: string, body: any, isCustom: boolean = true, URLName?: any, headers?: any ): Promise<any> => {
  IsBaseURL(isCustom,URLName);
  return new Promise(function (resolve, reject) {
    axios
      .post(url, body, { headers })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const patchService = (url: string, body: any,isCustom?: boolean, URLName?: any, headers?: any ): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom, URLName);

  return new Promise(function (resolve, reject) {
    axios
      .patch(url, body, { headers })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const fileUploadService = (url: string, file: any,isCustom?: boolean ): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);

  return new Promise(function (resolve, reject) {
    axios
      .post(url, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};


const putService = (
  url: string,
  body: any,
  isCustom?: boolean,
  token?: string
): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);
  if (token) {
    return new Promise(function (resolve, reject) {
      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }
  return new Promise(function (resolve, reject) {
    axios
      .put(url, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const formDataService = (url: string, body: any,isCustom?: boolean ): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);

  return new Promise(function (resolve, reject) {
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const deleteService = (url: string,isCustom?: boolean ): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);

  return new Promise(function (resolve, reject) {
    axios
      .delete(url)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

const postPutService = (url: string, body: any, method: "post" | "put" | "patch", isCustom?: boolean): Promise<any> => {
  IsBaseURL(isCustom === undefined ? true : isCustom);

  return new Promise(function (resolve, reject) {
    axios
    [method](url, body)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const commonService = {
  getServiceWithParams,
  postService,
  patchService,
  getServices,
  fileUploadService,
  formDataService,
  deleteService,
  putService,
  postPutService
};
