import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  debounce,
  styled,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  handleKeyNumberPress,
  handleKeyDecimalPress,
} from "forms/hooks/customHook";
import "./form.scss";
import HoverImagePopover from "./helperText";
import { isMobile } from "react-device-detect";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { StaticDatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import EventIcon from "@mui/icons-material/Event";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Failed } from "forms/helpers/toast.helper";

export const InputField = ({
  fieldName,
  values,
  helperText,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyDecimalNumber = false,
}: InputFieldtypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TextField
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
          placeholder={placeholder ? placeholder : ""}
          inputProps={{
            maxLength: length,
          }}
          multiline={multiline}
          minRows={minRow}
          InputProps={
            adorament
              ? {
                  [adorament]: (
                    <InputAdornment
                      position={position ? position : "start"}
                      disablePointerEvents={handleIconClick ? false : true}
                    >
                      <IconButton onClick={handleIconClick}>{Icon}</IconButton>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          disabled={disabled}
          error={Boolean(errors)}
          type={type}
          onKeyPress={(e) => {
            onlyDecimalNumber && handleKeyDecimalPress(e);
            onlyNumber && handleKeyNumberPress(e);
          }}
          id={id}
          helperText={!errors && helperText}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypes = {
  fieldName?: string;
  values: string | number | null | undefined;
  helperText?: string;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyDecimalNumber?: boolean;
  onInput?: (e: any) => void;
};

export const InputFieldMinus = ({
  fieldName,
  values,
  helperText,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyDecimalNumber = false,
}: InputFieldtypesMinus) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <TextField
          variant="outlined"
          value={values || ""}
          name={name}
          onChange={handleChange}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
          placeholder={placeholder ? placeholder : "-"}
          inputProps={{
            maxLength: length,
          }}
          multiline={multiline}
          minRows={minRow}
          InputProps={
            adorament
              ? {
                  [adorament]: (
                    <InputAdornment
                      position={position ? position : "start"}
                      disablePointerEvents={handleIconClick ? false : true}
                    >
                      <IconButton onClick={handleIconClick}>
                        <Icon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          disabled={disabled}
          error={Boolean(errors)}
          type={type}
          onKeyPress={(e) => {
            onlyDecimalNumber && handleKeyDecimalPress(e);
            onlyNumber && handleKeyNumberPress(e);
          }}
          id={id}
          helperText={!errors && helperText}
        />
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypesMinus = {
  fieldName?: string;
  values: string | number | null | undefined;
  helperText?: string;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyDecimalNumber?: boolean;
};

export const InputFieldMask = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  type = "text",
  disabled = false,
  placeholder,
  Icon,
  adorament,
  position,
  handleIconClick,
  length,
  minRow = 0,
  multiline = false,
  id = name,
  onlyNumber = false,
  onlyDecimalNumber = false,
}: InputFieldtypesMask) => {
  const theme = useTheme();
  const [onFocus, setOnFocus] = useState<boolean>();
  const [maskedValue, setMaskedValue] = useState<any>();

  const onFocusIN = (e: any) => {
    setOnFocus(false);
  };

  const onHandleFocusOut = (e: any) => {
    let value = e.target.value;
    let maskedValue =
      value.substring(0, value.length - 3).replace(/./g, "X") +
      value.substring(value.length - 3);
    setMaskedValue(maskedValue);
    setOnFocus(true);
  };
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {onFocus ? (
          <TextField
            value={maskedValue}
            onFocus={onFocusIN}
            sx={{
              width: "100%",

              backgroundColor: theme.palette.background.paper,
            }}
          />
        ) : (
          <TextField
            variant="outlined"
            value={values || ""}
            name={name}
            onChange={handleChange}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.paper,
            }}
            placeholder={placeholder ? placeholder : ""}
            inputProps={{
              maxLength: length,
            }}
            multiline={multiline}
            minRows={minRow}
            InputProps={
              adorament
                ? {
                    [adorament]: (
                      <InputAdornment
                        position={position ? position : "start"}
                        disablePointerEvents={handleIconClick ? false : true}
                      >
                        <IconButton onClick={handleIconClick}>
                          <Icon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            disabled={disabled}
            error={Boolean(errors)}
            type={type}
            onKeyPress={(e) => {
              onlyDecimalNumber && handleKeyDecimalPress(e);
              onlyNumber && handleKeyNumberPress(e);
            }}
            onBlur={onHandleFocusOut}
            id={id}
          />
        )}
      </Box>
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </>
  );
};
type InputFieldtypesMask = {
  fieldName?: string;
  values: string | number | null | undefined;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  type?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  adorament?: "endAdornment" | "startAdornment";
  position?: "start" | "end";
  Icon?: any;
  handleIconClick?: any;
  length?: number;
  multiline?: boolean;
  minRow?: number;
  id?: string;
  onlyNumber?: boolean;
  onlyDecimalNumber?: boolean;
};

// export default SelectField;

export const SelectField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  disabled = false,
  isRequired = true,
  placeholder = "",
  helphover = false,
  id = name,
  helperImg,
  helperContent,
  isValueClearRequired = false,
  handleClearSelectValue,
}: SelectFieldtypes) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
            {helphover && (
              <span>
                <HoverImagePopover img={helperImg} content={helperContent} />
              </span>
            )}
          </Typography>
        )}
        <TextField
          variant="outlined"
          id={id}
          name={name}
          select
          required={isRequired}
          onChange={handleChange}
          error={Boolean(errors)}
          sx={{
            width: "100%",
            // '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            //   display: ((values && isValueClearRequired) ? 'none' : 'inline'),
            // },
          }}
          value={values || ""}
          disabled={disabled}
          InputLabelProps={{
            shrink: false, // Display placeholder
          }}
          label={!values ? placeholder : ""}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the max height as needed
                },
              },
            },
            endAdornment: values && isValueClearRequired && (
              <IconButton
                size="small"
                sx={{ mr: ".75rem" }}
                onClick={handleClearSelectValue}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        >
          {!!options?.length &&
            options?.map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

type SelectFieldtypes = {
  fieldName?: string;
  values: string | number | null | undefined;
  handleChange: (_: any) => void;
  errors: any;
  name: string;
  options: { value: string | number | boolean; name: string }[];
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  id?: string;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
  isValueClearRequired?: boolean;
  handleClearSelectValue?: any;
};

export const SelectFieldLanguage = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  disabled = false,
  isRequired = true,
  placeholder = "",
  helphover = false,
  id = name,
  helperImg,
  helperContent,
  isValueClearRequired = false,
  handleClearSelectValue,
}: SelectFieldtypesLanguage) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {helphover && (
            <span>
              <HoverImagePopover img={helperImg} content={helperContent} />
            </span>
          )}
        </Typography>
        <TextField
          variant="outlined"
          id={id}
          name={name}
          select
          required={isRequired}
          onChange={handleChange}
          error={Boolean(errors)}
          sx={{
            width: "100%",
            // '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            //   display: ((values && isValueClearRequired) ? 'none' : 'inline'),
            // },
          }}
          value={values || ""}
          disabled={disabled}
          InputLabelProps={{
            shrink: false, // Display placeholder
          }}
          label={!values && "Please select a preferred langugae"}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the max height as needed
                },
              },
            },
            endAdornment: values && isValueClearRequired && (
              <IconButton
                size="small"
                sx={{ mr: ".75rem" }}
                onClick={handleClearSelectValue}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        >
          {!!options.length &&
            options.map((item: any) => (
              <MenuItem value={item.value} key={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      {errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </>
  );
};

type SelectFieldtypesLanguage = {
  fieldName: string;
  values: string | number | null | undefined;
  handleChange: (_: any) => void;
  errors: any;
  name: string;
  options: { value: string | number | boolean; name: string }[];
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  id?: string;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
  isValueClearRequired?: boolean;
  handleClearSelectValue?: any;
};

export const GroupRadioButton = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRow,
  isRowWithLabel = false,
  options,
  isRequired = true,
  id = name,
  disabled = false,
}: GroupRadioFieldtypes) => {
  const theme = useTheme();

  return (
    <FormControl
      sx={
        isRowWithLabel
          ? { display: "flex", flexDirection: "row" }
          : { display: "inline-block" }
      }
    >
      {fieldName && (
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}

      <RadioGroup
        row={isRow}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={values || ""}
        sx={{ marginLeft: isRowWithLabel ? "2rem" : "0" }}
        onChange={handleChange}
      >
        {!!options.length &&
          options.map((item: any) => (
            <FormControlLabel
              key={item.name}
              value={item.value}
              control={<Radio />}
              label={item.name}
              disabled={disabled}
            />
          ))}
      </RadioGroup>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </FormControl>
  );
};

type GroupRadioFieldtypes = {
  fieldName?: string;
  values: string | number | null | undefined | boolean;
  isRow: boolean;
  handleChange: (_: any) => void;
  errors?: any;
  name: string;
  options: { value: string | number | boolean; name: string }[];
  isRequired?: boolean;
  id?: string;
  disabled?: boolean;
  isRowWithLabel?: boolean;
};

export const AutoCompleteField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  isRequired = true,
  disabled = false,
  handleInputChange,
  id = name,
  loading = false,
  isFreeSolo = false,
  isValueClearRequired,
  handleClearSelectValue,
}: AutoCompleteFieldtypes) => {
  const theme = useTheme();

  const delay = 500;
  const debouncedHandleChange = debounce((value: any) => {
    handleInputChange && handleInputChange(value);
  }, delay);
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName && fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
        <Autocomplete
          value={values}
          // onChange={handleChange}
          onOpen={() => handleInputChange && handleInputChange("")}
          sx={{
            width: "100%",
          }}
          freeSolo={isFreeSolo}
          PaperComponent={(props) => (
            <Paper
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              {...props}
            />
          )}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          id={id}
          className="auto_complete_default_height"
          getOptionLabel={(option: any) => option?.name}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0, p: 1 } }}
              key={option?.value}
            >
              {option.name}
            </Box>
          )}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors)}
              placeholder={fieldName}
              variant="outlined"
              onChange={(e: any) => debouncedHandleChange(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: "absolute",
                      right: "5px",
                    }}
                  >
                    {values && isValueClearRequired && (
                      <IconButton
                        size="small"
                        sx={{ mr: ".75rem" }}
                        onClick={handleClearSelectValue}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <KeyboardArrowDownIcon
                      sx={{ color: theme.palette.primary.iconOrange }}
                    />
                  </InputAdornment>
                ),
                // endAdornment: values && isValueClearRequired && (
                //   <IconButton
                //     size="small"
                //     sx={{ mr: ".75rem" }}
                //     onClick={handleClearSelectValue}
                //   >
                //     <ClearIcon />
                //   </IconButton>
                // ),
                // endAdornment: null,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  display: "flex",
                  alignContent: "center", // Center the input text
                },
              }}
            />
          )}
          disabled={disabled}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type AutoCompleteFieldtypes = {
  fieldName?: string;
  values: any;
  handleChange: (_: any) => void;
  errors?: any;
  name: string;
  options: { value: string | number; name: string }[];
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  handleInputChange?: (_: any) => void;
  loading?: boolean;
  isFreeSolo?: boolean;
  isValueClearRequired?: boolean;
  handleClearSelectValue?: any;
};

export const MultiAutoCompleteField = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  options,
  isRequired = true,
  id = name,
  size,
  multiple = false,
  readOnly = false,
  handleInputChange,
  label,
  onOpen,
  limit = 3,
  loading = false,
  count,
}: MultiAutoCompleteFieldtypes) => {
  const theme = useTheme();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const delay = 500;
  const debouncedHandleChange = debounce((value: any) => {
    handleInputChange && handleInputChange(value);
  }, delay);
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName && fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
        <Autocomplete
          disableListWrap
          value={values}
          defaultValue={values}
          multiple={multiple}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disableCloseOnSelect={true}
          disabled={readOnly}
          id={id}
          limitTags={limit}
          className="auto_complete_default_height"
          getOptionLabel={(option) => option.name}
          size={size}
          options={options}
          loading={loading}
          onOpen={() => handleInputChange && handleInputChange("")}
          // open={true}
          sx={{
            width: "100%",
          }}
          // PaperComponent={(props) => (
          //   <Paper
          //     sx={{
          //       boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          //     }}
          //     {...props}
          //   />
          // )}
          onChange={(event: any, newValue: any) => {
            if (count) {
              if (newValue.length <= count) {
                handleChange(newValue);
              } else {
                Failed("You can select up to 3 items only!");
              }
            } else {
              handleChange(newValue);
            }
          }}
          renderOption={(props, option, { selected }) => {
            return (
              <Box
                {...props}
                component="li"
                key={option?.value}
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <span style={{ width: "90%", overflowWrap: "break-word" }}>
                  {option.name}
                </span>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors)}
              placeholder={values?.length > 0 ? "" : label}
              variant="outlined"
              // InputProps={{ ...params.InputProps, disableUnderline: true }}
              onChange={(e: any) => debouncedHandleChange(e.target.value)}
              InputProps={{
                ...params.InputProps,
                // Adjust styles for placeholder here
                style: {
                  // ...params.InputProps.style,
                  paddingBottom: "15px",
                  paddingTop: "5px", // Example to move the placeholder to the right
                  textAlign: "center", // Example to center the placeholder text
                },
              }}
            />
          )}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = limit;
            return (
              <Box>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option?.value}
                    label={option.name}
                    onClick={(event) => event.preventDefault()} // Disable click event on tags
                  />
                ))}
                {numTags > limitTags && (
                  <span style={{ marginLeft: 8 }}>+{numTags - limitTags}</span>
                )}
              </Box>
            );
          }}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type MultiAutoCompleteFieldtypes = {
  fieldName?: string;
  values: any;
  // values: { value: string | number | boolean; name: string } | null;
  handleChange: (_: any) => void;
  errors?: any;
  name: string;
  options: { value: string | number; name: string }[];
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  size?: "small" | "medium" | undefined;
  multiple?: boolean;
  readOnly?: boolean;
  count?: any;
  handleInputChange?: (_: any) => void;
  label?: string;
  onOpen?: () => void;
  limit?: number;
  loading?: boolean;
};

//Date Picker
export const InputDatePicker = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  disabled = false,
  placeholder,
  maxDate,
  minDate,
  format,
  id = name,
  actionBar = {},
  handleClearDate,
  isValueClearRequired,
}: datePickerFiledType) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const onKeyDown = (e: any) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <DatePicker
          value={values || ""}
          onChange={handleChange}
          sx={{
            width: "100%",
            border: errors ? "1px solid #f44336" : "",
            borderRadius: "5px",
          }}
          open={open}
          onClose={() => setOpen(!open)}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          className={
            disabled ? "datepicker-input-slot1" : "datepicker-input-slot"
          }
          slotProps={{
            textField: {
              name: name,
              id,
              onKeyPress: (e: any) => onKeyDown(e),
              disabled: isMobile ? false : true,
              error: Boolean(errors),
              onClick: (e) => setOpen(!open),
              // sx: (theme) => ({
              //   day: {
              //     sx: (theme) => ({
              //       "&.MuiPickersDay-root.Mui-selected": {
              //         backgroundColor: theme.palette.primary.main,
              //         color: "white"
              //       },
              //       "&.MuiPickersYear-root.Mui-selected": {
              //         backgroundColor: theme.palette.primary.main,
              //         "&:hover": {
              //           backgroundColor: theme.palette.primary.main,
              //         },
              //         "&:focus": {
              //           backgroundColor: theme.palette.primary.main,
              //         },
              //       },
              //     }),
              //   },
              // }),

              className: disabled
                ? "datepicker-input-slot1"
                : "datepicker-input-slot",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    {values && isValueClearRequired && (
                      <IconButton onClick={handleClearDate} size="small">
                        <ClearIcon />
                      </IconButton>
                    )}
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              },
            },
            actionBar: actionBar,
          }}
          format={format || "MM/DD/YYYY"}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type datePickerFiledType = {
  fieldName: string;
  // values: string | number | null | undefined;
  values: any;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  format?: string;
  id?: string;
  maxDate?: any;
  minDate?: any;
  actionBar?: any;
  handleClearDate?: any;
  isValueClearRequired?: any;
};

//Date Dob Picker
export const InputDatePickerdob = ({
  fieldName,
  values,
  errors,
  handleChange,
  name,
  isRequired = true,
  disabled = false,
  placeholder,
  maxDate,
  minDate,
  format,
  id = name,
  actionBar = {},
}: datePickerFiledTypeDob) => {
  const theme = useTheme();

  const [isDatePickerField, setDatePickerField] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isInitialLoad, setInitiLoad] = useState<boolean>(true);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (values && isInitialLoad) {
      handleDateChange(values);
    }
  }, [values]);

  const handleDateChange = (date: string | null) => {
    setInitiLoad(false);
    let formattedDate = null;
    if (date) {
      const parsedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };
      const monthMask = "XX";
      const dayMask = "XX";
      const month = monthMask.replace(/X/g, "X");
      const day = dayMask.replace(/X/g, "X");
      const year = parsedDate.getFullYear().toString();
      const maskedYear = "X" + year.slice(1); // Mask the first digit of the year
      formattedDate = parsedDate.toLocaleDateString("en-US", options);
      formattedDate = month + "/" + day + "/" + maskedYear;
    }
    setSelectedDate(formattedDate);
    handleChange && handleChange(date);
    setDatePickerField(false);
  };

  const handleOpenDatePicker = () => {
    setDatePickerField(true);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize="1rem"
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {!isDatePickerField ? (
          <TextField
            value={selectedDate}
            sx={{
              width: "100%",
            }}
            disabled={disabled}
            placeholder="DD/MM/YYYY"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={handleOpenDatePicker}
                  disabled={disabled}
                >
                  <EventIcon />
                </IconButton>
              ),
            }}
          />
        ) : (
          <DatePicker
            open={isDatePickerField}
            value={values || ""}
            onChange={handleDateChange}
            sx={{ width: "100%" }}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            className={
              disabled ? "datepicker-input-slot1" : "datepicker-input-slot"
            }
            slotProps={{
              textField: {
                name: name,
                id,
                onKeyPress: (e: any) => onKeyDown(e),
                disabled: isMobile ? false : true,
                error: Boolean(errors),
                className: disabled
                  ? "datepicker-input-slot1"
                  : "datepicker-input-slot",
              },
              actionBar: actionBar,
            }}
            format={format || "MM/DD/YYYY"}
          />
        )}
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type datePickerFiledTypeDob = {
  fieldName: string;
  values: any;
  handleChange?: (_: any) => void;
  errors?: any;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  format?: string;
  id?: string;
  maxDate?: any;
  minDate?: any;
  actionBar?: any;
};

export const PhoneField = (props: phoneField) => {
  const theme = useTheme();

  const {
    handleChange,
    name,
    errors,
    fieldName,
    isRequired = true,
    value,
    disabled,
    id,
    helperText,
  } = props;

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        <MuiPhoneNumber
          variant="outlined"
          name={name}
          InputProps={{
            name: name,
          }}
          onChange={(e) => handleChange({ target: { value: e, name: name } })}
          error={Boolean(errors)}
          value={value}
          disableAreaCodes={true}
          defaultCountry={"us"}
          preferredCountries={["us"]}
          disabled={disabled}
          id={id}
          sx={{ "& svg": { height: "1em" }, width: "100%" }}
          helperText={helperText}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type phoneField = {
  fieldName?: string;
  errors?: any;
  name: string;
  isRequired?: boolean;
  handleChange?: any;
  value?: string;
  disabled?: boolean;
  id?: string;
  helperText?: string;
};

export const PhoneFieldMask = (props: phoneFieldMask) => {
  const theme = useTheme();
  const [onFocus, setOnFocus] = useState<boolean>();
  const [maskedValue, setMaskedValue] = useState<any>();

  const {
    handleChange,
    name,
    errors,
    fieldName,
    isRequired = true,
    value,
    disabled,
    id,
  } = props;

  const onFocusIN = (e: any) => {
    setOnFocus(false);
  };

  const onHandleFocusOut = (e: any) => {
    let value = e.target.value;
    let maskedValue = value.replace(/[a-zA-Z0-9]/g, "X");
    const newStr = maskedValue.slice(0, -3);
    const str = value.substring(12);
    const updatedMobile = newStr + str;
    setMaskedValue(updatedMobile);
    setOnFocus(true);
  };

  // useEffect(() => {
  //   onHandleFocusIn(value);
  // },[value]);

  const onHandleFocusIn = (values: any) => {
    let maskedValue = values.replace(/[a-zA-Z0-9]/g, "X"); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3);
    const str = values.substring(12);
    const updatedMobile = newStr + str;
    setMaskedValue(updatedMobile);
    setOnFocus(true);
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {onFocus ? (
          <TextField
            value={maskedValue}
            onFocus={onFocusIN}
            disabled={disabled}
            sx={{
              width: "100%",

              backgroundColor: theme.palette.background.paper,
            }}
          />
        ) : (
          <MuiPhoneNumber
            variant="outlined"
            name={name}
            InputProps={{
              name: name,
            }}
            onBlur={onHandleFocusOut}
            onChange={(e) => handleChange({ target: { value: e, name: name } })}
            error={Boolean(errors)}
            value={value}
            disableAreaCodes={true}
            defaultCountry={"us"}
            preferredCountries={["us"]}
            disabled={disabled}
            id={id}
            sx={{ "& svg": { height: "1em" }, width: "100%" }}
          />
        )}
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type phoneFieldMask = {
  fieldName?: string;
  errors?: any;
  name: string;
  isRequired?: boolean;
  handleChange?: any;
  value?: string;
  disabled?: boolean;
  id?: string;
};

export const PhoneFieldMask1 = (props: PhoneFieldMask1) => {
  const theme = useTheme();
  const [onFocus, setOnFocus] = useState<boolean>();
  const [maskedValue, setMaskedValue] = useState<any>();

  const {
    handleChange,
    name,
    errors,
    fieldName,
    isRequired = true,
    value,
    disabled,
    id,
  } = props;

  const onFocusIN = (e: any) => {
    setOnFocus(false);
  };

  const onHandleFocusOut = (e: any) => {
    let value = e.target.value;
    let maskedValue =
      value.substring(0, value.length - 3).replace(/./g, "X") +
      value.substring(value.length - 3);
    setMaskedValue(maskedValue);
    setOnFocus(true);
  };

  useEffect(() => {
    onHandleFocusIn(value);
  }, [value]);

  const onHandleFocusIn = (values: any) => {
    let maskedValue = values.replace(/[a-zA-Z0-9]/g, "X"); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3);
    const str = values.substring(12);
    const updatedMobile = newStr + str;
    setMaskedValue(updatedMobile);
    setOnFocus(true);
  };

  return (
    <>
      <Box>
        {fieldName && (
          <Typography
            py="10px"
            fontWeight={400}
            fontSize={"1rem"}
            color={theme.palette.text.dark}
          >
            {fieldName}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </Typography>
        )}
        {onFocus ? (
          <TextField
            value={maskedValue}
            onFocus={onFocusIN}
            disabled={disabled}
            sx={{
              width: "100%",

              backgroundColor: theme.palette.background.paper,
            }}
          />
        ) : (
          <MuiPhoneNumber
            variant="outlined"
            name={name}
            InputProps={{
              name: name,
            }}
            onBlur={onHandleFocusOut}
            onChange={(e) => handleChange({ target: { value: e, name: name } })}
            error={Boolean(errors)}
            value={value}
            disableAreaCodes={true}
            defaultCountry={"us"}
            preferredCountries={["us"]}
            disabled={disabled}
            id={id}
            sx={{ "& svg": { height: "1em" }, width: "100%" }}
          />
        )}
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

type PhoneFieldMask1 = {
  fieldName?: string;
  errors?: any;
  name: string;
  isRequired?: boolean;
  handleChange?: any;
  value?: string;
  disabled?: boolean;
  id?: string;
};

export const SearchInputFiled = ({
  fieldName,
  isRequired,
  placeholder,
  values = "",
  name,
  handleChange,
  startIcon,
  endIcon,
  handleSearchClear,
  errors,
}: SearchFiled) => {
  const theme = useTheme();
  return (
    <Box>
      {fieldName && (
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}
      <TextField
        variant="outlined"
        value={values || ""}
        name={name}
        onChange={handleChange}
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.paper,
        }}
        placeholder={placeholder ? placeholder : ""}
        error={Boolean(errors)}
        InputProps={{
          style: { display: "flex", alignItems: "center" },
          startAdornment: startIcon && (
            <IconButton edge="start" aria-label="start-icon">
              {startIcon}
            </IconButton>
          ),
          endAdornment: endIcon && (
            <IconButton
              edge="end"
              aria-label="end-icon"
              disabled={values && values?.length > 0 ? false : true}
              sx={{ visibility: !!values?.length ? "visible" : "hidden" }}
              onClick={handleSearchClear}
            >
              {endIcon}
            </IconButton>
          ),
        }}
      />
      {errors && (
        <FormHelperText
          error
          id="standard-weight-helper-text-email-login"
          sx={{ mt: 0.3, p: 0 }}
        >
          {errors}
        </FormHelperText>
      )}
    </Box>
  );
};

type SearchFiled = {
  fieldName?: string;
  isRequired?: boolean;
  placeholder?: string;
  values: string | null | undefined;
  name?: string;
  handleChange?: (_: any) => any;
  startIcon?: any;
  endIcon?: any;
  handleSearchClear?: (_: any) => void;
  errors?: any;
};

export const AutocompleteFieldWithGroupBy = ({
  handleChange,
  values,
  fieldName,
  errors,
  name,
  options,
  isRequired = true,
  disabled = false,
  id = name,
  multiple = false,
  helphover = false,
  helperImg,
  helperContent,
}: AutoCompleteWithGroupBy) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName && fieldName}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {helphover && (
            <span>
              <HoverImagePopover img={helperImg} content={helperContent} />
            </span>
          )}
        </Typography>
        <Autocomplete
          multiple={multiple}
          value={values}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          id={id}
          options={options}
          PaperComponent={(props) => (
            <Paper
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              {...props}
            />
          )}
          getOptionLabel={(option: any) => option?.name}
          groupBy={(option: any) => option?.groupHeader}
          renderOption={(props, option: any) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0, p: 1 } }}
              {...props}
            >
              {option.name}
            </Box>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors)}
              id={id}
              // placeholder={fieldName}
              variant="outlined"
            />
          )}
          sx={{
            width: "100%",
          }}
          disabled={disabled}
        />
      </Box>
      {errors && <FormHelperText error>{errors}</FormHelperText>}
    </>
  );
};

const GroupHeader = styled("div")(({ theme }) => ({
  fontSize: "1rem",
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  textTransform: "capitalize",
  fontWeight: "700",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

type AutoCompleteWithGroupBy = {
  options: { value: string | number; name: string }[];
  handleChange: any;
  values: any;
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  errors?: any;
  name: string;
  fieldName?: string;
  multiple?: boolean;
  helphover?: boolean;
  helperImg?: any;
  helperContent?: any;
};
