import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import dayjs from "dayjs";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import deleteAlert from "component/HOC/deleteAlert";

const ViewSubscriptionPlan = () => {
  const [planData, setPlanData] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const userId: any = useAppSelector((state) => state?.user?.user);
  const location = useLocation();
  const theme = useTheme();
  const tableStyle: any = {
    borderCollapse: "collapse",
    width: "50%",
  };
  const tableRowStyle: any = {
    border: `1px solid #dddddd`,
    textAlign: "left",
    padding: "8px",
    height: "60px",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "InterVariableFont",
  };
  const tableHeadingStyle: any = {
    border: `1px solid #dddddd`,
    textAlign: "left",
    padding: "8px",
    height: "60px",
    backgroundColor: "#E4E4E4",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "InterVariableFont",
  };
  useEffect(() => {
    getPlan();
  }, []);

  const getPlan = () => {
    setLoading(true);
    commonService
      .getServices(`plan/${location?.state?.id}`)
      .then((res) => {
        console.log(res);
        setPlanData(res?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setLoading(false);
      });
  };
  const handleInactive = () => {
    deleteAlert({
      title: "Inactive",
      message: "Are you sure?",
      confirmButtonText: "Yes",
      onConfirmed: () => handleInactiveAction(),
    });
  };
  const handleInactiveAction = () => {
    setLoading(true);
    commonService
      .patchService(`plan/${location?.state?.id}/status`, {
        is_active: false,
      })
      .then((res) => {
        console.log(res);
        getPlan();
        setLoading(false);
        Success(res?.data?.message);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setLoading(false);
        Failed(errorMessage(error, "Oops! Something Went Wrong"));
      });
  };
  const handleActive = () => {
    deleteAlert({
      title: "Active",
      message: "Are you sure?",
      confirmButtonText: "Yes",
      onConfirmed: () => handleactiveAction(),
    });
  };
  const handleactiveAction = () => {
    setLoading(true);
    commonService
      .patchService(`plan/${location?.state?.id}/status`, {
        is_active: true,
      })
      .then((res) => {
        console.log(res);
        getPlan();
        setLoading(false);
        Success(res?.data?.message);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setLoading(false);
        Failed(errorMessage(error, "Oops! Something Went Wrong"));
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box sx={{ padding: "0 20px" }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "1% 1.5%" }}>
          <Link
            underline="hover"
            color="inherit"
            href="/subscription_management"
          >
            Subscription Management
          </Link>
          <Typography>Plan 1</Typography>
        </Breadcrumbs>
        <Card
          sx={{
            marginTop: 0.5,
            borderRadius: 2,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardContent>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  fontWeight={600}
                  fontSize={"24px"}
                  color={"#141313"}
                >
                  {planData?.name}
                </Typography>
                <Chip
                  label={planData?.is_active ? "active" : "inactive"}
                  size="small"
                  sx={{ fontWeight: 400, fontSize: "14px", color: "#000000" }}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ margin: "0 10px" }}
                />
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#071B36"}
                >
                  Plan Created On:
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#071B36"}
                >
                  {planData?.created_at
                    ? dayjs(planData?.created_at)?.format(`YYYY/MM/DD`)
                    : null}
                </Typography>
              </Box>

              <Typography
                marginTop={1.5}
                fontWeight={400}
                fontSize={"14px"}
                color={"#626163"}
              >
                {planData?.description}
              </Typography>
            </Box>

            <Divider sx={{ margin: "10px 0" }} />
            <Grid container spacing={2}>
              <Grid item lg={3}>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#626163"}
                >
                  Last Edited On
                </Typography>
                <Typography
                  marginTop={1}
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#0D2B42"}
                >
                  {planData?.created_at
                    ? dayjs(planData?.updated_at)?.format(`YYYY/MM/DD`)
                    : null}
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#626163"}
                >
                  Price
                </Typography>
                <Typography
                  marginTop={1}
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#0D2B42"}
                >
                  &#x20b9;{planData?.price}
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#626163"}
                >
                  Payment Type
                </Typography>
                <Typography
                  marginTop={1}
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#0D2B42"}
                >
                  {planData?.plan_period === 30
                    ? "Monthly"
                    : planData?.plan_period === 90
                    ? "Quarterly"
                    : "Yearly"}
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <Typography
                  fontWeight={400}
                  fontSize={"14px"}
                  color={"#626163"}
                >
                  Hires/year
                </Typography>
                <Typography
                  marginTop={1}
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#0D2B42"}
                >
                  {planData?.hires}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box sx={{ marginTop: "40px" }}>
          <table style={tableStyle}>
            <tr>
              <th colSpan={2} style={tableHeadingStyle}>
                Feature
              </th>
            </tr>
            <tr>
              <td style={tableRowStyle}>
                Hires/{" "}
                {planData?.plan_period === 30
                  ? "Month"
                  : planData?.plan_period === 90
                  ? "Quarter"
                  : "Year"}{" "}
              </td>
              <td style={tableRowStyle}>{planData?.hires}</td>
            </tr>
            <tr>
              <td style={tableRowStyle}>Job Slots</td>
              <td style={tableRowStyle}> {planData?.job_slots}</td>
            </tr>
            <tr>
              <td style={tableRowStyle}>Job Branding</td>
              <td style={tableRowStyle}>
                {planData?.featured_job_slots
                  ? planData?.featured_job_slots
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td style={tableRowStyle}>Search Results</td>
              <td style={tableRowStyle}>{planData?.search_results}</td>
            </tr>
            <tr>
              <td style={tableRowStyle}>CV Views</td>
              <td style={tableRowStyle}>{planData?.cv_views}</td>
            </tr>
            <tr>
              <td style={tableRowStyle}>Job Posts</td>
              <td style={tableRowStyle}>
                {planData?.job_posts ? planData?.job_posts : "N/A"}
              </td>
            </tr>
          </table>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "15vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: 2,
            mt: 2,
            boxShadow: "0px -4px 8px 0px #33414D1A",
            alignItems: "center",
            height: "80px",
            marginTop: "auto",
          }}
        >
          <Button
            sx={{
              padding: "10px 40px",
              marginRight: 2,
              border: `1px solid ${theme.palette.primary.buttonOrange}`,
            }}
            onClick={() => navigate(-1)}
          >
            back
          </Button>
          {planData?.is_active ? (
            <Button
              sx={{
                padding: "10px 40px",
                background: "transparent",
                marginRight: 2,
                border: `1px solid ${theme.palette.primary.buttonOrange}`,
              }}
              onClick={handleInactive}
            >
              Make Inactive
            </Button>
          ) : (
            <Button
              sx={{
                padding: "10px 40px",
                background: "transparent",
                marginRight: 2,
                border: `1px solid ${theme.palette.primary.buttonOrange}`,
              }}
              onClick={handleActive}
            >
              Make Active
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ViewSubscriptionPlan;
