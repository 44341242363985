import {
  Box,
  Breadcrumbs,
  Button,
  FormHelperText,
  Grid,
  Link,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { AutoCompleteField, InputField, PhoneField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { employerValidation } from "forms/hooks/validateRules";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { parsePhoneNumber } from "forms/hooks/customHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const EmployerEnquiry = () => {
  const [queryoptions, setQueryOptions] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDetailsChange = (event: any) => {
    if (event.target.value.length <= 500) {
      setUpdateValue("details", event.target.value);
    }
  };
  const employerValues = {
    name: null,
    query_type: null,
    organisation_name: null,
    mobile_number: "+91",
    organisation_email: null,
    details: null,
  };

  const handleSubmitEmployer = () => {
    const mobile_number = parsePhoneNumber(values.mobile_number);
    const formData = {
      name: values?.name,
      organization_name: values?.organisation_name,
      email: values?.organisation_email,
      country_code: mobile_number?.countryCode,
      mobile_number: mobile_number?.mobileNumber,
      enquiry_type: values?.query_type?.value,
      message: values?.details,
    };

    commonService
      .postService("/enquiry/employer", formData)
      .then((res) => {
        Success(res?.data?.message);
        navigate(`/enquiry_management`, {
          state: {
            value: "recruiter",
          },
        });
      })
      .catch((error) => {
        console.error("Error in submission: ", error);
        Failed(errorMessage(error, "Oops! Something went wrong"));
      });
  };
  function getQuery() {
    commonService
      .getServiceWithParams(`master`, {
        type: "query_type",
      })
      .then((res: any) => {
        setQueryOptions(res?.data?.data?.query_type);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getQuery();
  }, []);

  const { values, errors, handleChange, handleSubmit, setUpdateValue } =
    useForm(employerValues, handleSubmitEmployer, employerValidation);
  return (
    <>
      <Box>
        <Box sx={{ padding: "1% 1.5%" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/enquiry_management">
              Enquiry Management
            </Link>
            <Typography color="text.primary">
              Create Enquiry - Recruiter
            </Typography>
          </Breadcrumbs>

          <Typography
            marginTop={3}
            fontWeight={600}
            fontSize={"18px"}
            color={"#0A1F41"}
          >
            Query Details
          </Typography>
        </Box>
        <Box>
          <form onSubmit={handleSubmit}>
            <Box sx={{ height: "68vh", padding: "0 1.5%" }}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <InputField
                    fieldName="Name"
                    values={values?.name || ""}
                    name="name"
                    errors={errors?.name}
                    handleChange={handleChange}
                    id="name"
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutoCompleteField
                    fieldName="Query Type"
                    values={values.query_type}
                    name="query_type"
                    errors={errors.query_type}
                    handleChange={(query_type: any) => {
                      setUpdateValue("query_type", query_type);
                    }}
                    options={queryoptions ? queryoptions : []}
                    id="query_type"
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    fieldName="Organisation Name"
                    values={values?.organisation_name || ""}
                    name="organisation_name"
                    errors={errors?.organisation_name}
                    handleChange={handleChange}
                    id="organisation_name"
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PhoneField
                    fieldName="Mobile Number"
                    value={values.mobile_number || ""}
                    name="mobile_number"
                    errors={errors.mobile_number}
                    handleChange={handleChange}
                    id="mobile_number"
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    fieldName="Organisation Email ID"
                    values={values?.organisation_email || ""}
                    name="organisation_email"
                    errors={errors?.organisation_email}
                    handleChange={handleChange}
                    id="organisation_email"
                    isRequired={true}
                  />
                </Grid>
              </Grid>

              <Typography
                marginTop={5}
                fontWeight={600}
                fontSize={"18px"}
                color={"#0A1F41"}
              >
                Message
              </Typography>
              <Typography
                fontWeight={500}
                fontSize={"14px"}
                marginTop={2}
                marginBottom={1}
              >
                Enter Details
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <TextareaAutosize
                  id="details"
                  name="details"
                  value={values?.details ? values?.details : ""}
                  onChange={handleDetailsChange}
                  placeholder=""
                  minRows={6}
                  maxRows={6}
                  style={{
                    width: "100%",
                    fontFamily: "InterVariableFont",
                    resize: "none",
                    fontWeight: 400,
                    fontSize: "16px",
                    border: errors?.details ? "1px solid red" : "",
                  }}
                />

                <Box
                  sx={{
                    textAlign: "left",
                    marginTop: "6px",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#6B778C",
                  }}
                >
                  {values?.details?.length ? values?.details?.length : 0}/500
                </Box>
                {errors?.details && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                    sx={{ mt: 0.3, p: 0 }}
                  >
                    {errors?.details}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                gap: 2,
                mt: 2,
                boxShadow: "0px -4px 8px 0px #33414D1A",
                alignItems: "center",
                height: "80px",
              }}
            >
              <Button
                sx={{
                  padding: "10px 40px",
                  background: "transparent",
                  border: `1px solid ${theme.palette.primary.buttonOrange}`,
                }}
                onClick={() =>
                  navigate(`/enquiry_management`, {
                    state: {
                      value: "recruiter",
                    },
                  })
                }
              >
                Cancel
              </Button>

              <Button type="submit" sx={{ padding: "10px 40px", mr: 2 }}>
                Create Enquiry
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default EmployerEnquiry;
