import React, { useState } from "react";
import { Box } from "@mui/material";
import EnhancedTable from "component/tables/selectableTable";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import axios from "axios";
import Loader from "ui-component/Loader";

const PaymentList = ({
  tableData,
  page,
  rowsPerPage,
  count,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  isLoading,
}: any) => {
  const [isloading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const headCell = [
    {
      id: "employer_id.organization_name",
      numeric: false,
      disablePadding: true,
      label: "Organisation Name",
      isSort: true,
    },
    {
      id: "recruiter_id.first_name",
      numeric: false,
      disablePadding: false,
      label: "Contact Person",
      isSort: true,
    },
    {
      id: "recruiter_id.email",
      numeric: false,
      disablePadding: false,
      label: "Organisation Email ID",
      isSort: true,
    },
    {
      id: "plan_id.name",
      numeric: false,
      disablePadding: false,
      label: "Subscription",
      isSort: true,
    },
    {
      id: "payment_date",
      numeric: false,
      disablePadding: false,
      label: "Renewed on",
      isSort: true,
    },
    {
      id: "expiry_date",
      numeric: false,
      disablePadding: false,
      label: "Expiring on",
      isSort: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isSort: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
      isSort: false,
    },
  ];

  const handleDownload = async (receiptId: any) => {
    setLoading(true);
    try {
      const pdf = await axios({
        method: "post",
        url: `${process.env.REACT_APP_END_URL}payment/invoice-pdf`,
        data: { receipt: [receiptId], start_date: "", end_date: "" },
        responseType: "blob",
      });
      saveAs(pdf?.data, `Invoice.pdf`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      Failed(errorMessage(error, "Oops! Something Went Wrong"));
      setLoading(false);
    }
  };
  const actionFunction = (id: string, actionText: string) => {
    const data: any = tableData.find((res: any) => id === res?.id);
    const user_id = data?.user_id;

    if (actionText === "download") {
      handleDownload(id);
    }

    if (actionText === "employer_id.organization_name") {
      navigate(`/view_employer`, {
        state: {
          id: id,
        },
      });
    }
  };
  return (
    <Box>
      {isloading && <Loader />}
      <Box>
        <EnhancedTable
          headCells={headCell}
          rows={tableData}
          total={count}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          isCompanyLink={true}
          isEdit={false}
          isView={false}
          isSelectable={false}
          isLoading={isLoading}
          actionFunction={actionFunction}
          isMoreVert={true}
          isPagination={tableData ? true : false}
          isUserLink={true}
          isSingleCheck={true}
          isCheckedRound={true}
        />
      </Box>
    </Box>
  );
};

export default PaymentList;
