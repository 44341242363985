import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import AddIcon from "asset/images/images/addIcon.svg";
import EmptyImage from "asset/images/images/empty.svg";
import CreateIcon from "@mui/icons-material/Create";
import { InputField, SelectField } from "forms/hoc/formfield";
import { OutlinedButton } from "component/button";
import { leaderValidationSchema } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import CreateIcon1 from "asset/images/images/create-icon-1.svg";
import Spotlight from "./spotlight";
import ProfileImage from "asset/images/images/profile-image.svg";
import CloseIcon from "@mui/icons-material/Close";

const EmployerLife = () => {
  const [openLeadersDialog, setOpenLeadersDialog] = useState(false);
  const [openSpotlightsDialog, setOpenSpotlightsDialog] = useState(false);
  const [openCompanyPhotos, setOpenCompanyPhotos] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();

  const handleEditLeaders = () => {
    setOpenLeadersDialog(true);
  };

  const handleCloseLeaders = () => {
    setOpenLeadersDialog(false);
  };

  const handleEditSpotlights = () => {
    setOpenSpotlightsDialog(true);
  };

  const handleCloseSpotlights = () => {
    setOpenSpotlightsDialog(false);
  };
  const handleEditCompanyPhotos = () => {
    setOpenCompanyPhotos(true);
  };
  const handleCloseCompanyPhotos = () => {
    setOpenCompanyPhotos(false);
  };

  const handleCreateIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpdateLogin = () => {
    console.log("data");
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };

  const leaderValues = {
    first_name: null,
    last_name: null,
    designation: null,
    link: null,
    short_intro: null,
  };

  const roles = [
    {
      name: "Founder",
      value: "1",
    },
    {
      name: "CTO",
      value: "2",
    },
    {
      name: "CEO",
      value: "3",
    },
  ];

  const { values, errors, handleChange, handleSubmit } = useForm(
    leaderValues,
    handleUpdateLogin,
    leaderValidationSchema
  );

  return (
    <>
      <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", mt: 3 }}>
        <CardContent
          sx={{ color: "#616B73", fontWeight: 500, fontSize: "14px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={600} fontSize={"18px"} color={"#15171E"}>
              Meet Our Leaders
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", color: "#AB5B0B" }}
              onClick={handleEditLeaders}
              style={{ cursor: "pointer" }}
            >
              <img src={AddIcon} alt="edit" style={{ paddingLeft: "30px" }} />
              <Typography sx={{ pl: 1, fontWeight: 600, fontSize: "14px" }}>
                Add
              </Typography>
            </Box>
          </Box>
          <Dialog open={openLeadersDialog} maxWidth="md">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: 2,
              }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  fontSize: "24px",
                  color: "#15171E",
                  flexGrow: 1,
                }}
              >
                Edit Leaders
              </DialogTitle>
              <IconButton
                onClick={handleCloseLeaders}
                aria-label="close"
                sx={{ color: "#AB5B0B" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Box position="relative" display="inline-flex">
                    <img
                      src={ProfileImage}
                      alt="Profile Image"
                      style={{
                        backgroundColor: "#F0F8FF",
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: "#192732",
                        borderRadius: "50%",
                        padding: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleCreateIconClick}
                    >
                      <img
                        src={CreateIcon1}
                        alt="Create Icon"
                        style={{ width: "15px", height: "15px" }}
                      />
                    </Box>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputField
                        fieldName="First name"
                        values={values.first_name}
                        name="first_name"
                        errors={errors?.first_name}
                        handleChange={handleChange}
                        id="first_name"
                        isRequired={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        fieldName="Last name"
                        values={values.last_name}
                        name="last_name"
                        errors={errors?.last_name}
                        handleChange={handleChange}
                        id="last_name"
                        isRequired={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        fieldName="Designation"
                        values={values.designation}
                        name="designation"
                        errors={errors?.designation}
                        handleChange={handleChange}
                        options={roles}
                        placeholder="select"
                        id="designation"
                        isRequired={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        fieldName="Link to Linkedin"
                        values={values.link}
                        name="link"
                        errors={errors?.link}
                        handleChange={handleChange}
                        id="link"
                        isRequired={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        fieldName="Short Intro (150 words)"
                        values={values.short_intro}
                        name="short_intro"
                        errors={errors?.short_intro}
                        handleChange={handleChange}
                        id="short_intro"
                        isRequired={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ mb: 2 }}>
              <OutlinedButton
                size={"small"}
                label={"cancel"}
                isBold={true}
                handleClick={handleCloseLeaders}
              />
              <Box sx={{ mx: 1 }} />
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                  borderRadius: "48px",
                  marginRight: 3,
                }}
                type="submit"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
          <Divider sx={{ my: 2, width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <img src={EmptyImage} alt="empty" />
            <Typography fontWeight={600} fontSize={"16px"} color={"#15171E"}>
              No Leaders have been added
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", mt: 3 }}>
        <CardContent
          sx={{ color: "#616B73", fontWeight: 500, fontSize: "14px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={600} fontSize={"18px"} color={"#15171E"}>
              Spotlights
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", color: "#AB5B0B" }}
              onClick={handleEditSpotlights}
              style={{ cursor: "pointer" }}
            >
              <img src={AddIcon} alt="edit" style={{ paddingLeft: "30px" }} />
              <Typography sx={{ pl: 1, fontWeight: 600, fontSize: "14px" }}>
                Add
              </Typography>
            </Box>
          </Box>

          <Spotlight
            openSpotlightsDialog={openSpotlightsDialog}
            handleCloseSpotlights={handleCloseSpotlights}
          />
          <Divider sx={{ my: 2, width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <img src={EmptyImage} alt="empty" />
            <Typography fontWeight={600} fontSize={"16px"} color={"#15171E"}>
              No details available
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", mt: 3 }}>
        <CardContent
          sx={{ color: "#616B73", fontWeight: 500, fontSize: "14px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={600} fontSize={"18px"} color={"#15171E"}>
              Company Photos
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", color: "#AB5B0B" }}
              onClick={handleEditCompanyPhotos}
              style={{ cursor: "pointer" }}
            >
              <img src={AddIcon} alt="edit" style={{ paddingLeft: "30px" }} />
              <Typography sx={{ pl: 1, fontWeight: 600, fontSize: "14px" }}>
                Add
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 2, width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <img src={EmptyImage} alt="empty" />
            <Typography fontWeight={600} fontSize={"16px"} color={"#15171E"}>
              No Photos available
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default EmployerLife;
