import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  useTheme,
  Modal,
  IconButton,
  Avatar,
} from "@mui/material";
import { InputField, PhoneField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { profileValidation } from "forms/hooks/validateRules";
import { useEffect, useRef, useState } from "react";
import { readFile } from "helpers/function.helper";
import NoUserImage from "asset/images/images/no-user.png";
import EditIcon from "asset/images/images/edit-icon.svg";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { parsePhoneNumber } from "forms/hooks/customHook";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ContainedButton, OutlinedButton } from "component/button";
import ImageUploadWithCrop from "forms/hoc/image-upload";
import { useDispatch } from "react-redux";
import { setUserRedux } from "store/slice/user.slice";
import Loader from "ui-component/Loader";

const Profile = () => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imageUrl, setImageUrl] = useState<any>("");
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const childRef: any = useRef();
  const userId: any = useAppSelector((state) => state?.user?.user);
  const [profileImage, setProfileImage] = useState("");
  const [userName, setUserName] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    maxHeight: { xs: "500px", sm: "100% !important" },
    minWidth: "300px",
    overflowY: "auto !important",
  };

  const profileValues = {
    name: null,
    mobile: "+91",
    email: null,
    password: null,
    newPassword: null,
    confirmPassword: null,
  };

  const handleSubmitProfile = () => {
    const mobile_number = parsePhoneNumber(values.mobile);
    const formData = {
      name: values?.name,
      email: values?.email,
      mobile: mobile_number?.mobileNumber,
      country_code: mobile_number?.countryCode,
      // is_active: userId?.is_active,
      // designation: userId?.designation,
      // access_level: userId?.access_level,
    };

    commonService
      .patchService(`/admin/${userId?._id}/update`, formData)
      .then((res) => {
        setIsLoading(false);
        dispatch(setUserRedux(res?.data.data));
        navigate(`/profile`);
        Success(res?.data.message);
      })
      .catch((error) => {
        Failed(errorMessage(error, "Oops! Something Went Wrong"));
        setIsLoading(false);
      });
  };

  const handleApplyClick = async () => {
    if (childRef.current) {
      const result = await childRef.current.showCroppedImage();
      setImageUrl("");
      setUpdateValue("profile", result);
      uploadProfileImage(result);
    }
  };
  const uploadProfileImage = async (file: File) => {
    const formData = new FormData();
    formData.append("profile", file);
    try {
      const response = await commonService.postService(
        `/admin/${userId?._id}/profile`,
        formData
      );
      const newImageUrl = response.data.data?.photo;
      dispatch(setUserRedux(response.data.data));
      setImageUrl("");
      getUserDetails();
      Success("Profile image uploaded successfully!");
    } catch (error) {
      Failed(errorMessage(error, "Image upload failed"));
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setUpdateValue,
    setPatchValue,
  } = useForm(profileValues, handleSubmitProfile, profileValidation);
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      let imageDataUrl = await readFile(file);
      setImageUrl(imageDataUrl);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleCreateIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleClose = () => {};
  const getUserDetails = () => {
    setIsLoading(true);
    commonService
      .getServices(`/admin/${userId?._id}`)
      .then((res) => {
        const userData = res?.data?.data[0];
        setPatchValue({
          name: userData?.name,
          mobile: userData?.country_code + userData?.mobile,
          email: userData?.email,
        });
        setUserName(userData?.name);
        setProfileImage(userData?.photo);
        setIsLoading(false);
      })
      .catch((error) => {
        Failed(errorMessage(error, "Oops! Something Went Wrong"));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Box sx={{ padding: "1% 1.5%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="text.primary">Profile</Typography>
        </Breadcrumbs>
        <Card sx={{ marginTop: 4, boxShadow: 1 }}>
          <CardContent sx={{ padding: 0 }}>
            <Box
              sx={{
                backgroundColor: "#0302290A",
                height: 133,
                display: "flex",
                alignItems: "center",
                padding: 2,
              }}
            >
              <Box position="relative">
                {userId?.photo ? (
                  <Box
                    component={"img"}
                    height={140}
                    width={140}
                    src={userId?.photo}
                    alt="Profile Image"
                    sx={{
                      marginTop: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: "140px",
                      width: "140px",
                      marginTop: "60px",
                      background: "grey",
                    }}
                  />
                )}
                <Box
                  sx={{
                    position: "absolute",
                    top: 156,
                    right: 0,
                    borderRadius: "50%",
                    backgroundColor: "#192732",
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleCreateIconClick}
                >
                  <img
                    src={EditIcon}
                    alt="Create Icon"
                    style={{ width: "24px", height: "24px" }}
                  />
                </Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/gif, image/jpeg, image/png"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                <Modal
                  open={imageUrl !== ""}
                  onClose={() => {
                    setImageUrl("");
                  }}
                >
                  <Box sx={style}>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: "2px",
                        top: "1px",
                      }}
                      onClick={() => {
                        setImageUrl("");
                      }}
                    >
                      <CloseIcon
                        sx={{
                          fontSize: "20px",
                          color: theme.palette.primary.iconOrange,
                        }}
                      />
                    </IconButton>
                    <Box>
                      <ImageUploadWithCrop
                        imageUrl={imageUrl}
                        ref={childRef}
                        aspect={4 / 4}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "center",
                        mt: 3,
                        justifyContent: "space-between",
                      }}
                    >
                      <OutlinedButton
                        label="Cancel"
                        size="small"
                        isBold={true}
                        handleClick={handleClose}
                      />

                      <ContainedButton
                        label="Apply"
                        size="small"
                        isBold={true}
                        handleClick={handleApplyClick}
                      />
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Box>
            <Box sx={{ padding: "1% 1.5%", marginTop: 6 }}>
              <Typography variant="h2" color={"#0A1F41"}>
                {userName}
              </Typography>
              <Divider style={{ marginTop: "20px" }} />
              <Box sx={{ marginY: 2 }} />
              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <InputField
                    fieldName="Name"
                    values={values?.name || ""}
                    name="name"
                    errors={errors?.name}
                    handleChange={handleChange}
                    id="name"
                    isRequired={false}
                  />
                </Grid>
                <Grid item lg={4}>
                  <PhoneField
                    fieldName="Mobile Number"
                    value={values.mobile || ""}
                    name="mobile"
                    errors={errors.mobile}
                    handleChange={handleChange}
                    id="mobile"
                    isRequired={false}
                  />
                </Grid>
                <Grid item lg={4}>
                  <InputField
                    fieldName="Email ID"
                    values={values?.email || ""}
                    name="email"
                    errors={errors?.email}
                    handleChange={handleChange}
                    id="email"
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          gap: 2,
          mt: "18%",
          boxShadow: "0px -4px 8px 0px #33414D1A",
          alignItems: "center",
          height: "80px",
        }}
      >
        <Button
          sx={{
            padding: "10px 40px",
            background: "transparent",
            border: `1px solid ${theme.palette.primary.buttonOrange}`,
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          sx={{ padding: "10px 40px", mr: 3 }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Box>
    </>
  );
};

export default Profile;
