import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  TextField,
  Avatar,
  IconButton,
  useTheme,
  Breadcrumbs,
  Link,
  Tab,
} from "@mui/material";
import EditIcon from "asset/images/images/edit-image.svg";
import RImage from "asset/images/images/r-symbol-image.svg";
import EditIcon1 from "asset/images/images/edit-image-1.svg";
import BasicInfo from "./basic-info";
import AboutOrganisation from "./about-organisation";
import EmployerProfileImage from "asset/images/images/employer-profile.svg";
import { InputField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { editValidationSchema } from "forms/hooks/validateRules";
import CreateIcon from "@mui/icons-material/Create";
import EllipseImage from "asset/images/images/ellipse-image.svg";
import { OutlinedButton } from "component/button";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EmployerLife from "./employer-life";

const EmployerProfile = () => {
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const [editCover, setEditCover] = useState(false);
  const [value, setValue] = useState("basic_info");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleCreateIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleClose = () => {
    setEdit(false);
  };
  const handleEditCoverClick = () => {
    setEditCover(true);
  };
  const handleEditClose = () => {
    setEditCover(false);
  };

  const handleUpdateLogin = () => {
    console.log("data");
  };
  const editValues = {
    organisation_name: null,
    gender: null,
    service_status: null,
  };
  const gender = [
    {
      name: "Male",
      value: "1",
    },
    {
      name: "Female",
      value: "2",
    },
  ];
  const serviceStatus = [
    {
      name: "Active",
      value: "1",
    },
    {
      name: "Retired",
      value: "2",
    },
  ];

  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(editValues, handleUpdateLogin, editValidationSchema);
  return (
    <>
      <Box style={{ position: "relative", width: "100%" }}>
        <img
          src={EmployerProfileImage}
          alt="Employer Profile Image"
          style={{ width: "100%" }}
        />
        <Breadcrumbs
          sx={{
            position: "absolute",
            top: 20,
            left: 20,
            color: "white",

            padding: "8px 16px",
            borderRadius: "4px",
          }}
        >
          <Typography
            color="white"
            marginLeft={2}
            fontWeight={500}
            fontSize={"14px"}
          >
            Home
          </Typography>
          <Typography color="white" fontWeight={500} fontSize={"14px"}>
            Profile
          </Typography>
        </Breadcrumbs>
        <Button
          variant="contained"
          onClick={handleEditCoverClick}
          startIcon={<img src={EditIcon} alt="edit" />}
          style={{
            position: "absolute",
            bottom: "20px",
            right: "10px",
            padding: "0.5rem 1rem",
            backgroundColor: "#FF7E00",
            color: "#15171E",
            border: "none",
            borderRadius: "48px",
            cursor: "pointer",
          }}
        >
          Edit cover image
        </Button>
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            top: "18rem",
            display: "flex",
          }}
        >
          <img src={RImage} alt="R image" />
          <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "24px",
                  ml: 5,
                  mt: "40px",
                  color: "#15171E",
                }}
              >
                R System International
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                <img
                  src={EditIcon1}
                  alt="edit"
                  style={{
                    paddingLeft: "30px",
                    color: "#AB5B0B",
                    cursor: "pointer",
                  }}
                  onClick={handleEditClick}
                />
                <Typography
                  sx={{
                    pl: 1,
                    color: "#AB5B0B",
                    cursor: "pointer",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                  onClick={handleEditClick}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", ml: "40px" }}>
                <Typography
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#616B73"}
                >
                  IT service & solutions
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", ml: "20px" }}>
                <img
                  src={EllipseImage}
                  alt="ellipse"
                  style={{ marginRight: "15px" }}
                />
                <Typography
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#616B73"}
                >
                  Artificial intelligence
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", ml: "20px" }}>
                <img
                  src={EllipseImage}
                  alt="ellipse"
                  style={{ marginRight: "15px" }}
                />
                <Typography
                  fontWeight={500}
                  fontSize={"16px"}
                  color={"#616B73"}
                >
                  Cloud & DevOps
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: "150px" }}>
        <TabContext value={value}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
            sx={{ borderBottom: 1, borderColor: "divider", mt: "15px" }}
          >
            <Tab label="Basic Info " value="basic_info" />
            <Tab label="Life" value="life" />
          </TabList>
          <TabPanel value="basic_info">
            <BasicInfo />
            <AboutOrganisation />
          </TabPanel>
          <TabPanel value="life">
            <EmployerLife />
          </TabPanel>
        </TabContext>
      </Box>
      <Dialog open={edit} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            color: " #000000",
          }}
        >
          Edit Details
        </DialogTitle>
        <Divider sx={{ mb: 1, borderColor: "#000000", width: "100%" }} />
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Box position="relative" display="inline-flex">
                <Avatar
                  style={{
                    width: "156px",
                    height: "156px",
                    backgroundColor: theme?.palette?.primary?.textMid,
                  }}
                  src="/broken-image.jpg"
                />
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={handleCreateIconClick}
                >
                  <CreateIcon />
                </IconButton>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    fieldName="Organisation Name"
                    values={values.organisation_name}
                    name="organisation_name"
                    errors={errors.organisation_name}
                    handleChange={handleChange}
                    isRequired={false}
                    id="organisation_name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    fieldName="Gender"
                    values={values.gender}
                    name="gender"
                    errors={errors?.gender}
                    handleChange={handleChange}
                    options={gender}
                    placeholder="select"
                    id="gender"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    fieldName="Service Status"
                    values={values.service_status}
                    name="service_status"
                    errors={errors?.service_status}
                    handleChange={handleChange}
                    options={serviceStatus}
                    placeholder="select"
                    id="service_status"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    placeholder="Search services"
                    values={undefined}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <OutlinedButton
            size={"small"}
            label={"cancel"}
            isBold={true}
            handleClick={handleClose}
          ></OutlinedButton>
          <Button
            sx={{
              fontWeight: 600,
              fontSize: "18px",
              borderRadius: "48px",
              marginRight: 2,
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployerProfile;
