export default function componentStyleOverrides(theme: any) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "1rem",
          borderRadius: "48px",
          padding: ".75rem 1.5rem",
          boxShadow: "none",
          color: theme.colors.textBtn,
          backgroundColor: theme.colors.buttonOrange,
          "&:hover": {
            backgroundColor: theme.colors.buttonHover,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root.Mui-selected": {
            border: "1px solid #1b00ff",
          },
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        "MuiDialog-paper": {
          maxWidth: "800px !important",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.colors.textInverse,
          // paddingTop: "10px",
          // paddingBottom: "10px",
          borderRadius: "10",
          width:"90%",
          // marginLeft:"10px",
          padding:"20px",
          "&.Mui-selected": {
            color: theme.colors?.buttonOrange,
            backgroundColor: theme.colors?.backgroundGrey,
            borderRadius: "10",
            width:"90%",
            marginLeft:"10px",
            padding:"10px",
            "&:hover": {
            
              backgroundColor: theme.colors?.backgroundGrey,
            },
            "& .MuiListItemIcon-root": {
              color: theme.paper,
            },
          },
          "&:hover": {
            backgroundColor: theme.colors?.backgroundGrey,
            borderRadius: "10",
            width:"90%",
            marginLeft:"10px",
            padding:"10px",
            // color: theme.colors?.buttonOrange,
            // width:"90%",
            // marginLeft:"10px",
            // color: theme.menuSelected,
            "& .MuiListItemIcon-root": {
              // color: theme.menuSelected
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.main-menu": {
            color: theme.darkTextPrimary,
            paddingTop: "5px",
            paddingBottom: "5px",
            "& svg": {
              color: theme.darkTextPrimary,
              fontSize: "25px",
            },
            "&.Mui-selected": {
              color: theme?.iconColor,
              backgroundColor: theme.backgroundDefault,
              position: "relative",
              // backgroundColor: '#0090D6',
              borderRadius: "0",
              "&:hover": {
                backgroundColor: theme.divider,
              },
              "& .MuiListItemIcon-root": {
                color: theme.menuSelected,
              },
              "& svg": {
                color: theme?.iconColor,
              },
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: "-20px",
                left: "50%",
                width: "0",
                height: "0",
                borderWidth: "8px",
                borderStyle: "solid",
                borderColor: "transparent",
                borderTopColor: theme?.iconColor,
                marginLeft: "-4px",
              },
            },
            "&:hover": {
              backgroundColor: theme.divider,
              // color: theme.menuSelected,
              "& .MuiListItemIcon-root": {
                // color: theme.menuSelected
              },
            },
          },
          "&.sub-menu": {
            color: theme.backgroundDefault,
            paddingTop: "5px",
            paddingBottom: "5px",
            "& svg": {
              color: theme.backgroundDefault,
              fontSize: "25px",
            },
            "&.Mui-selected": {
              color: theme.backgroundDefault,
              backgroundColor: theme.colors.primaryMain,
              // borderRadius: "5px",
              "&:hover": {
                backgroundColor: theme.colors.primaryMain,
              },
              "&.Mui-selected": {
                color: theme.colors.buttonOrange,
                backgroundColor: theme.colors.backgroundDarkGrey,
                borderBottom: `3px solid  ${theme.colors.buttonOrange}`,
                // "&:hover": {
                //   backgroundColor: theme.colors.buttonColor,
                // },
                "& .MuiListItemIcon-root": {
                  color: theme.backgroundDefault,
                },
                "& svg": {
                  color: theme.backgroundDefault,
                },
              },
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          // color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
          maxHeight: "48px",
          paddingLeft: "8px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme?.borderOutline,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme?.colors?.buttonOrange} !important`,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.paper,
          background: theme.colors?.primary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected:focus": {
            backgroundColor: theme?.colors?.primaryMain,
            color: "white",
          },
          "&.Mui-selected:hover": {
            backgroundColor: theme?.colors?.primaryMain,
            color: "white",
          },
          "&.Mui-selected": {
            backgroundColor: theme?.colors?.primaryMain,
            color: "white",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.MuiSelect-icon": {
            color: theme?.colors.iconOrange,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            textTransform: "unset",
            fontWeight: 600,
            "&.Mui-selected": {
              color: theme?.colors?.textBtn,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            fontSize: ".875rem",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiLink-root": {
            color: theme?.colors?.iconOrange,
            fontWeight: 600,
          },
        },
      },
    },
  };
}
