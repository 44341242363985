import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import { InputField, PhoneField, SelectField } from "forms/hoc/formfield";
import { parsePhoneNumber } from "forms/hooks/customHook";
import useForm from "forms/hooks/useForm";
import { subscriptionValidation } from "forms/hooks/validateRules";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { useNavigate } from "react-router-dom";

const SubscriptionEnquiry = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const subscriptionValues = {
    name: "",
    organisation_name: "",
    mobile_number: "+91",
    organisation_email: "",
    number_of_hires: "",
  };
  const number_of_hires = [
    {
      name: "100",
      value: "1",
    },
    {
      name: "250",
      value: "2",
    },
    {
      name: "500",
      value: "3",
    },
  ];

  function handleSubmitSubscription() {
    const mobile_number = parsePhoneNumber(values.mobile_number);
    const formData = {
      name: values?.name,
      organization_name: values?.organisation_name,
      email: values?.organisation_email,
      country_code: mobile_number?.countryCode,
      mobile_number: mobile_number?.mobileNumber,
      hires: values?.number_of_hires,
    };

    commonService
      .postService("/enquiry/subscription", formData)
      .then((res) => {
        Success(res?.data?.message);
        navigate(`/enquiry_management`, {
          state: {
            value: "subscription",
          },
        });
      })
      .catch((error) => {
        console.error("Error in submission: ", error);
        Failed(errorMessage(error, "Oops! Something went wrong"));
      });
  }

  const { values, errors, handleChange, handleSubmit } = useForm(
    subscriptionValues,
    handleSubmitSubscription,
    subscriptionValidation
  );

  return (
    <>
      <Box>
        <Box sx={{ padding: "1% 1.5%" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/enquiry_management">
              Enquiry Management
            </Link>
            <Typography color="text.primary">
              Create Enquiry - Subscription
            </Typography>
          </Breadcrumbs>

          <Typography
            marginTop={3}
            fontWeight={600}
            fontSize={"18px"}
            color={"#0A1F41"}
          >
            Query Details
          </Typography>
        </Box>
        <Box sx={{ marginTop: 1, height: "70vh" }}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ height: "68vh", padding: "0 1.5%" }}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <InputField
                    fieldName="Name"
                    values={values?.name || ""}
                    name="name"
                    errors={errors?.name}
                    handleChange={handleChange}
                    id="name"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={4}>
                  <InputField
                    fieldName="Organisation Name"
                    values={values?.organisation_name || ""}
                    name="organisation_name"
                    errors={errors?.organisation_name}
                    handleChange={handleChange}
                    id="organisation_name"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PhoneField
                    fieldName="Mobile Number"
                    value={values.mobile_number || ""}
                    name="mobile_number"
                    errors={errors.mobile_number}
                    handleChange={handleChange}
                    id="mobile_number"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    fieldName="Organisation Email ID"
                    values={values?.organisation_email || ""}
                    name="organisation_email"
                    errors={errors?.organisation_email}
                    handleChange={handleChange}
                    id="organisation_email"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectField
                    fieldName="Number of Hires/Year"
                    values={values.number_of_hires}
                    name="number_of_hires"
                    errors={errors.number_of_hires}
                    handleChange={handleChange}
                    options={number_of_hires}
                    placeholder="select"
                    id="number_of_hires"
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                gap: 2,
                mt: 2,
                boxShadow: "0px -4px 8px 0px #33414D1A",
                alignItems: "center",
                height: "80px",
              }}
            >
              <Button
                sx={{
                  padding: "10px 40px",
                  background: "transparent",
                  border: `1px solid ${theme.palette.primary.buttonOrange}`,
                }}
                onClick={() =>
                  navigate(`/enquiry_management`, {
                    state: {
                      value: "subscription",
                    },
                  })
                }
              >
                Cancel
              </Button>

              <Button type="submit" sx={{ padding: "10px 40px", mr: 2 }}>
                Create Enquiry
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionEnquiry;
