import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";

interface IButton {
  size: "small" | "medium" | "large";
  label: string;
  type?: "submit" | "button" | "reset";
  handleClick?: () => void;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isBold?: boolean;
}

export function ContainedButton({
  size,
  label,
  type,
  handleClick,
  isDisabled = false,
  isFullWidth = false,
}: IButton) {
  return (
    <Button
      variant="contained"
      color="primary"
      size={size}
      disabled={isDisabled}
      type={type ? type : "button"}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
      sx={{
        width: isFullWidth ? "100%" : "auto",
      }}
    >
      {label}
    </Button>
  );
}

export function OutlinedButton({
  size,
  label,
  type,
  handleClick,
  isDisabled = false,
  isFullWidth = false,
  isBold = false,
}: IButton) {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      color="primary"
      size={size}
      disabled={isDisabled}
      type={type ? type : "button"}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
      sx={{
        width: isFullWidth ? "100%" : "auto",
        backgroundColor: "transparent !important",
        borderWidth: "2px",
        borderColor: theme?.palette?.primary?.buttonOrange,
        padding: size === "small" ? "8px 24px 8px 24px" : "12px 24px 12px 24px",
        fontWeight: isBold ? "600" : "400",
      }}
    >
      {label}
    </Button>
  );
}
