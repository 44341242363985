import { toast } from "react-toastify";

const options = {
  type: toast.TYPE.INFO,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  progress: undefined,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  autoClose: 5000,
};

export const Success = (message: string) => {
  const success = { ...options, type: toast.TYPE.SUCCESS };
  return toast.success(message, { ...success, theme: "colored" });
};

export const Failed = (message: string) => {
  const success = { ...options, type: toast.TYPE.ERROR };
  return toast.error(message, { ...success, theme: "colored" });
};

export const Info = (message: string) => {
  const success = { ...options, type: toast.TYPE.INFO };
  return toast.info(message, { ...success, theme: "colored" });
};

export const Warning = (message: string) => {
  const success = { ...options, type: toast.TYPE.WARNING };
  return toast.error(message, { ...success, theme: "colored" });
};

export const closeToaster = () => {
    toast.dismiss(); // Close the toaster using the reference
};
