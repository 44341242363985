import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PdfLogo from "../../asset/images/pdflogo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./selectedTable.scss";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Switch, { SwitchProps } from "@mui/material/Switch";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import filtericon from "../../asset/images/images/filtericon.svg";
import { hexToRgb, styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {
  dateFormat,
  dateTimeFormatWithoutSecond,
  parsePhoneNumberformat,
} from "forms/hooks/customHook";
import NoRecordFound from "component/tables/NoRecordFound";
import { formatLicense } from "forms/hooks/customHook";
import PaginationComponent from "../pagination";
import IosShareIcon from "@mui/icons-material/IosShare";
// import ResendMail from "../../asset/images/mail-resend-icon.svg";
// import MailVerified from "../../asset/images/mail-verified.svg";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RedoIcon from "@mui/icons-material/Redo";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import Editicon from "asset/images/images/editIcon.svg";
import DeleteIcon from "asset/images/images/deleteIcon.svg";
import RadioGroupRating from "component/HOC/Review";
function EnhancedTableHead(props: EnhancedTableHeadTypes) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isSelectable,
    isSingleCheck,
  } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {isSelectable && (
          <TableCell
            padding="checkbox"
            sx={isSingleCheck ? { border: "0" } : {}}
          >
            {!isSingleCheck && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            )}
          </TableCell>
        )}
        {headCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={headCell.id === "status" ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="tableheading"
            sx={{ border: "0" }}
          >
            {headCell?.isSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}{" "}
                <img
                  src={filtericon}
                  alt="filterimage"
                  className="filterimage"
                />
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span> {headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type EnhancedTableHeadTypes = {
  numSelected: number;
  onRequestSort: (_: any, __: any) => void;
  onSelectAllClick: (_: any) => void;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headCells: any[];
  isSelectable: boolean;
  isSingleCheck?: boolean;
};

type EnhancedTableTypes = {
  page?: number;
  rowsPerPage?: number;
  handleChangePage?: (_: any, __: any) => void;
  handleChangeRowsPerPage?: (_: any) => void;
  headCells: any[];
  handleRequestSort?: (_: any, __: any) => void;
  rows: any[];
  order?: "asc" | "desc";
  orderBy?: string;
  isSelectable?: boolean;
  selected?: any;
  setSelected?: (_: any) => void;
  total?: number;
  actionFunction?: (_: string, __: string, ___?: any, ____?: any) => void;
  resendMail?: (_: any) => void;
  isEdit?: boolean;
  isFirstNameLink?: boolean;
  isUserLink?: boolean;
  isAudit?: boolean;
  isCompanyLink?: boolean;
  isView?: boolean;
  isreInvite?: boolean;
  isDelete?: boolean;
  isKyc?: boolean;
  isMail?: boolean;
  isActionButton?: boolean;
  isMoreVert?: boolean;
  setActive?: (_: string, __: boolean) => void;
  reInvite?: (_: string) => void;
  redoKyc?: (_: string) => void;
  isLoading?: boolean;
  Openmodal?: (_: string, __: string) => void;
  isPagination?: boolean;
  isNoRecordFound?: boolean;
  isSingleCheck?: boolean;
  maxHeightValue?: any;
  isCheckedRound?: boolean;
  width?: string | number;
  isExport?: boolean;
};
type TableCellType = {
  row: any;
  head: any;
  i: number;
  isMail: boolean;
  resendMail?: (_: any) => void;
  isView: boolean;
  isreInvite: boolean;
  isKyc: boolean;
  actionFunction?: (_: string, __: string, ___?: any, ____?: any) => void;
  isEdit?: boolean;
  isFirstNameLink?: boolean;
  isUserLink?: boolean;
  isAudit?: boolean;
  isCompanyLink?: boolean;
  isDelete?: boolean;
  isActionButton?: boolean;
  isMoreVert?: boolean;
  Openmodal?: (_: string, __: string) => void;
  setActive?: (_: string, __: boolean) => void;
  redoKyc?: (_: string) => void;
  reInvite?: (_: string) => void;
  isExport?: boolean;
};
const EnhancedTableCell = ({
  row,
  head,
  i,
  isMail,
  resendMail,
  isView,
  isreInvite,
  isKyc,
  actionFunction,
  isEdit,
  isFirstNameLink,
  isUserLink,
  isAudit,
  isCompanyLink,
  isDelete,
  isActionButton,
  isMoreVert,
  Openmodal,
  setActive,
  reInvite,
  redoKyc,
  isExport,
}: TableCellType) => {
  const backgroundColor = row?.color_code ? hexToRgb(row?.color_code) : "";

  const [flg_mailVerified, setFlg_mailVerified] = React.useState(false);
  const [isHover_mailVerified, setIsHover_mailVerified] = React.useState(false);

  const [flg_isReinvite, setFlg_isReinvite] = React.useState(false);
  const [isHover_isReinvite, setIsHover_isReinvite] = React.useState(false);

  const [flg_isKyc, setFlg_isKyc] = React.useState(false);
  const [isHover_isKyc, setIsHover_isKyc] = React.useState(false);

  const [flg_isView, setFlg_isView] = React.useState(false);
  const [isHover_isView, setIsHover_isView] = React.useState(false);

  const [flg_isEdit, setFlg_isEdit] = React.useState(false);
  const [isHover_isEdit, setIsHover_isEdit] = React.useState(false);

  const [flg_isDelete, setFlg_isDelete] = React.useState(false);
  const [isHover_isDelete, setIsHover_isDelete] = React.useState(false);

  const [flg_isMore, setFlg_isMore] = React.useState(false);
  const [isHover_isMore, setIsHover_isMore] = React.useState(false);

  const [flg_export, setFlg_export] = React.useState(false);
  const [isHover_export, setIsHover_export] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const IOSSwitch = styled((props: SwitchProps, checked: any) => (
    // <Switch
    //   focusVisibleClassName=".Mui-focusVisible"
    //   disableRipple
    //   {...props}
    // />
    <Switch
      checked={checked}
      {...props}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      // onChange={onchange}
      // inputProps={{ "aria-label": "controlled" }}
      sx={{ color: "#283891" }}
    />
  ))(({ theme }) => ({
    width: 37,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#2ECA45"
              : theme.palette.primary.buttonColor,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      marginTop: 1,
      width: 15,
      height: 15,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  if (row[head]) {
    return (
      <Typography variant="subtitle1" fontWeight="bold">
        {row[head.id]}
      </Typography>
    );
  }
  switch (head.id) {
    case "name":
      return (
        <>
          {isUserLink ? (
            <>
              <span
                onClick={() => {
                  actionFunction && actionFunction(row.id, "name", i);
                }}
                style={{
                  color: theme.palette.primary.iconOrange,
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                {row[head.id]}
              </span>
              {row?.feedback && row?.candidate_id && (
                <Typography sx={{ fontSize: "13px", mt: 1 }}>
                  #{row?.candidate_id}
                </Typography>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                // fontWeight: "600",
              }}
            >
              {row[head.id]}
            </div>
          )}
        </>
      );

    case "company_name":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "firstname":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "status":
      return (
        <Box display={"flex"} justifyContent="center">
          <Chip
            label={row[head.id]}
            sx={{
              background: row.actionBackgroundColor,
              borderRadius: "5px",
              minWidth: "8rem",
              color: row.color_code,
              fontSize: "1rem",
              fontWeight: "600",
            }}
          />
        </Box>
      );
    case "organization.status":
      return (
        <Box display={"flex"} justifyContent="center">
          <Chip
            label={row[head.id]}
            sx={{
              background: row.actionBackgroundColor,
              borderRadius: "5px",
              minWidth: "8rem",
              color: row.color_code,
              fontSize: "1rem",
              fontWeight: "600",
            }}
          />
        </Box>
      );
    case "vehicle_identification_number":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "action":
      return (
        <Stack
          flexDirection="row"
          justifyContent={isAudit ? "flex-start" : "space-evenly"}
        >
          {isMail && (
            <>
              {!row?.email_verified ? (
                <Tooltip
                  title="Resend Verification Email"
                  open={!flg_mailVerified && isHover_mailVerified}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    // aria-controls={open ? "long-menu" : undefined}
                    // aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                      setFlg_mailVerified(!flg_mailVerified);
                      resendMail && resendMail(row.id);
                    }}
                    onMouseOver={() => setIsHover_mailVerified(true)}
                    onMouseLeave={() => setIsHover_mailVerified(false)}
                  >
                    {/* <MailOutlineIcon sx={{ color: "#283891" }} /> */}
                    {/* <img src={ResendMail} width="24" /> */}
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip
                    title="Email Verified"
                    open={!flg_mailVerified && isHover_mailVerified}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      // aria-controls={open ? "long-menu" : undefined}
                      // aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onMouseOver={() => setIsHover_mailVerified(true)}
                      onMouseLeave={() => setIsHover_mailVerified(false)}
                    >
                      {/* <MailOutlineIcon
                        sx={{ color: "#283891", opacity: "0.4" }}
                      /> */}
                      {/* <img
                        src={MailVerified}
                        style={{ opacity: "0.4" }}
                        width="24"
                      /> */}
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
          {isView && (
            <Tooltip title="View" open={!flg_isView && isHover_isView}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isView(!flg_isView);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "view", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isView(true)}
                onMouseLeave={() => setIsHover_isView(false)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ color: theme.palette.primary.buttonColor }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isreInvite && (
            <Tooltip
              title="Reinvite"
              open={!flg_isReinvite && isHover_isReinvite}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isReinvite(!flg_isReinvite);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "reinvite", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isReinvite(true)}
                onMouseLeave={() => setIsHover_isReinvite(false)}
              >
                {/* <RedoIcon sx={{ color: theme.palette.primary.buttonColor }} /> */}
                <PersonAddAltIcon
                  sx={{ color: theme.palette.primary.buttonColor }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isKyc && (
            <Tooltip title="RedoKYC" open={!flg_isKyc && isHover_isKyc}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isKyc(!flg_isKyc);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "redo", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isKyc(true)}
                onMouseLeave={() => setIsHover_isKyc(false)}
              >
                <RedoIcon sx={{ color: theme.palette.primary.buttonColor }} />
              </IconButton>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip title="Edit" open={!flg_isEdit && isHover_isEdit}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isEdit(!flg_isEdit);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "edit", i, row);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isEdit(true)}
                onMouseLeave={() => setIsHover_isEdit(false)}
              >
                {/* <EditOutlinedIcon
                  sx={{ color: theme.palette.primary.buttonColor }}
                /> */}
                <img src={Editicon} alt="edit" />
              </IconButton>
            </Tooltip>
          )}

          {isDelete && (
            <Tooltip title="Delete" open={!flg_isDelete && isHover_isDelete}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isDelete(!flg_isDelete);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "delete", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isDelete(true)}
                onMouseLeave={() => setIsHover_isDelete(false)}
              >
                {/* <DeleteOutlineIcon
                  sx={{ color: theme.palette.primary.buttonColor }}
                /> */}
                <img src={DeleteIcon} alt="delete" />
              </IconButton>
            </Tooltip>
          )}
          {isExport && (
            <Tooltip title="Export" open={!flg_export && isHover_export}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_export(!flg_export);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "export", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_export(true)}
                onMouseLeave={() => setIsHover_export(false)}
              >
                <ExitToAppOutlinedIcon
                  sx={{ color: theme.palette.primary.buttonColor }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isMoreVert && row?.status !== "archived" && (
            <>
              <Tooltip title="More" open={!flg_isMore && isHover_isMore}>
                {!row?.isApproveActionDisable ? (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    onMouseOver={() => setFlg_isMore(true)}
                    onMouseLeave={() => setIsHover_isMore(false)}
                    disabled={row?.status === "closed"}
                  >
                    <MoreVertIcon
                      sx={{ color: theme.palette.primary.iconOrange }}
                    />
                  </IconButton>
                ) : (
                  <div style={{ visibility: "hidden", padding: "1.3rem" }} />
                )}
              </Tooltip>
              {!row?.enquiry_type ? (
                row?.job_applicants ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    // sx={{ margin: "2rem" }}
                  >
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(row.job_id, "screened", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      Screened
                    </MenuItem>
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(row.job_id, "interviewed", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      Interviewed
                    </MenuItem>
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(row.job_id, "shortlisted", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      Shortlisted
                    </MenuItem>
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(row.job_id, "hired", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      Hired
                    </MenuItem>
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(row.job_id, "rejected", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      Rejected
                    </MenuItem>
                  </Menu>
                ) : row?.subscription ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    // sx={{ margin: "2rem" }}
                  >
                    {/* <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction && actionFunction(row.id, "raise", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      Raise Invoice
                    </MenuItem> */}
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(row.reference_id, "download", i);
                      }}
                      disabled={
                        row.status === "unpaid" ||
                        row.status === "pending" ||
                        row.status === "captured"
                          ? true
                          : false
                      }
                    >
                      Download Invoice
                    </MenuItem>
                  </Menu>
                ) : row?.reporter_type ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    // sx={{ margin: "2rem" }}
                  >
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0rem 1.2rem",
                        fontSize: "1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction && actionFunction(row.id, "resolved", i);
                      }}
                      disabled={row?.status === "resolved" ? true : false}
                    >
                      Resolve
                    </MenuItem>
                  </Menu>
                ) : (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    // sx={{ margin: "2rem" }}
                  >
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction && actionFunction(row.id, "edit", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      {/* <ListItemIcon>
                      <CheckCircleOutlineIcon
                        fontSize="medium"
                        // sx={{ color: "#56BF54" }}
                      />
                    </ListItemIcon> */}
                      Edit{" "}
                    </MenuItem>
                    {row?.designation && (
                      <MenuItem
                        sx={{
                          minWidth: "4rem",
                          padding: "0.5rem 1.2rem",
                          fontSize: "1.1rem",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          actionFunction && actionFunction(row.id, "reset", i);
                        }}
                        disabled={row.isButtonDisable}
                      >
                        {/* <ListItemIcon>
                     <CheckCircleOutlineIcon
                       fontSize="medium"
                       // sx={{ color: "#56BF54" }}
                     />
                   </ListItemIcon> */}
                        Reset Password{" "}
                      </MenuItem>
                    )}
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction &&
                          actionFunction(
                            row.id,
                            row?.status === "active" ? "disable" : "enable",
                            i
                          );
                      }}
                      disabled={row.isButtonDisable}
                    >
                      {/* <ListItemIcon>
                      <HighlightOffIcon
                        fontSize="medium"
                        // sx={{ color: "#EF415A" }}
                      />
                    </ListItemIcon> */}
                      {row?.status === "active" ? "Disable" : "Enable"}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        minWidth: "4rem",
                        padding: "0.5rem 1.2rem",
                        fontSize: "1.1rem",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        actionFunction && actionFunction(row.id, "archive", i);
                      }}
                      disabled={row.isButtonDisable}
                    >
                      {/* <ListItemIcon>
                      <HighlightOffIcon
                        fontSize="medium"
                        // sx={{ color: "#EF415A" }}
                      />
                    </ListItemIcon> */}
                      Archive{" "}
                    </MenuItem>
                  </Menu>
                )
              ) : (
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  // sx={{ margin: "2rem" }}
                >
                  <MenuItem
                    sx={{
                      minWidth: "4rem",
                      padding: "0.5rem 1.2rem",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      actionFunction && actionFunction(row.id, "closed", i);
                    }}
                    disabled={row.isButtonDisable}
                  >
                    Close
                  </MenuItem>
                </Menu>
              )}
            </>
          )}
          {isActionButton && !isMoreVert && (
            <Box>
              <Button
                variant="outlined"
                size="small"
                sx={{ minWidth: "4rem", padding: "0.5rem 1rem" }}
                onClick={() => {
                  actionFunction && actionFunction(row.id, "Accept", i);
                }}
                disabled={row.isButtonDisable}
              >
                {" "}
                Accept{" "}
              </Button>
              <Button
                sx={{ minWidth: "4rem", padding: "0.5rem 1rem", ml: 1 }}
                size="small"
                variant="outlined"
                color="error"
                onClick={() => {
                  actionFunction && actionFunction(row.id, "Reject", i);
                }}
                disabled={row.isButtonDisable}
              >
                {" "}
                Reject{" "}
              </Button>
            </Box>
          )}
          {isAudit && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                // alignItems: "start",
                // fontWeight: "600",
              }}
            >
              {row[head.id]}
            </div>
          )}
        </Stack>
      );
    case "dob":
      return dateFormat(row[head.id]);
    case "permit_expiration_date":
      return dateFormat(row[head.id]);
    case "driver_license_number":
      return formatLicense(row[head.id]);
    case "type":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {head?.isUserIcon && (
            <AccountCircleIcon sx={{ color: "#a5a5a5" }} fontSize="large" />
          )}
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "filesize":
      return row[head.id];
    // case "mobile_number":
    //   return parsePhoneNumberformat(row.country_code, row?.mobile_number);
    case "icons":
      return (
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          className="showIcon"
        >
          <IconButton>
            <PersonAddIcon />
          </IconButton>
          <IconButton>
            <FileDownloadIcon />
          </IconButton>
          <IconButton>
            <BorderColorIcon />
          </IconButton>
        </Stack>
      );
    case "button":
      return (
        <>
          {" "}
          <IconButton
            aria-label="more"
            id="long-button"
            // aria-controls={open ? "long-menu" : undefined}
            // aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={() => {
              Openmodal && Openmodal(row.id, "open");
            }}
          >
            <IosShareIcon sx={{ color: theme.palette.primary.buttonColor }} />
          </IconButton>
        </>
      );
    // case "email_verified":
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         alignItems: "center",
    //       }}
    //     >
    //       {!row?.email_verified ? (
    //         <MailOutlineIcon
    //           sx={{ color: "#2196f3" }}
    //           onClick={() => resendMail && resendMail(row.id)}
    //         />
    //       ) : (
    //         <></>
    //       )}
    //     </div>
    //   );
    case "is_active":
      return (
        <>
          <IOSSwitch
            checked={row.is_active}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setActive && setActive(row.id, event.target.checked)
            }
            // handleCheckChange={(event:) =>
            //   setActive && setActive(event.target.checked)
            // }
            // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            //   setActive && setActive(event.target.checked)
            // }
            // inputProps={{ "aria-label": "controlled" }}
            // sx={{ color: "#283891" }}
          />
        </>
      );
    case "template_type":
      return row[head.id]?.name;
    case "created_at":
      return (
        <>
          <span
            onClick={() => {
              actionFunction && actionFunction(row.id, "name", i);
            }}
            style={{
              // color: theme.palette.primary.iconOrange,
              fontWeight: row?.feedback ? "600" : "500",
              cursor: "pointer",
            }}
          >
            {row[head.id]}
          </span>
          {row?.feedback && (
            <Typography sx={{ fontSize: "13px", mt: 1 }}>
              {row?.created_time}
            </Typography>
          )}
        </>
      );

    case "recommend":
      return (
        <>
          <span
            style={{
              // color: theme.palette.primary.iconOrange,
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            <RadioGroupRating val={row?.rating} />
          </span>
          <Tooltip title={row?.comment} placement="bottom-start">
            <Typography
              sx={{
                mt: 1,
                // display: "inline-block", // Ensures the text behaves as an inline block
                overflow: "hidden", // Hides the overflow text
                textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                whiteSpace: "nowrap", // Prevents the text from wrapping
                verticalAlign: "bottom", //
                maxWidth: "250px",
              }}
            >
              {row?.comment}
            </Typography>
          </Tooltip>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
              maxWidth: "70%",
            }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
              <span
                key={number}
                style={{
                  border: "1px solid #D0D5DD",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: row?.recommend == number ? "#ab5b0b" : "none",
                  color: row?.recommend == number ? "white" : "none",
                }}
              >
                {number}
              </span>
            ))}
          </Box>
        </>
      );
    case "timestamp":
      return dateTimeFormatWithoutSecond(row[head.id]);
    case "path":
      return (
        <span
          onClick={() => {
            window.open(process.env.REACT_APP_IMG_URL + row?.path, "_blank");
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "#007aff",
          }}
        >
          {row?.path?.split("/")?.slice(-1)?.toString()}
        </span>
      );
    case "first_name":
      return (
        <>
          {isFirstNameLink && row?.status !== "archived" ? (
            <Box
              component={"a"}
              sx={{
                // color: "black",
                // fontSize: ".9rem",
                letterSpacing: "0.5px",
                // fontWeight: 500,
                textDecoration: "none",
                wordBreak: "break-all",
                // pt: 1,
                display: "block", // Ensures the box is a block element
                overflow: "hidden", // Hides the overflow text
                textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                whiteSpace: "nowrap",
                // maxWidth: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                actionFunction && actionFunction(row.id, "first_name", i);
              }}
            >
              <Tooltip title={row[head.id]}>
                <Typography
                  sx={{
                    // color: theme.palette.grey[800],
                    fontSize: "0.9rem",
                    letterSpacing: "0.5px",
                    // fontWeight: 600,
                    lineHeight: "1.5rem",
                    display: "inline-block", // Ensures the text behaves as an inline block
                    overflow: "hidden", // Hides the overflow text
                    textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                    whiteSpace: "nowrap", // Prevents the text from wrapping
                    verticalAlign: "bottom", //
                    maxWidth: "100px",
                    color: theme.palette.primary.iconOrange,
                    fontWeight: "600",
                  }}
                >
                  {row[head.id]}
                </Typography>
              </Tooltip>
            </Box>
          ) : (
            // <span
            //   onClick={() => {
            //     actionFunction && actionFunction(row.id, "first_name", i);
            //   }}
            //   style={{
            //     color: theme.palette.primary.iconOrange,
            //     fontWeight: "600",
            //   }}
            // >
            //   {row[head.id]}
            // </span>
            <Box
              component={"a"}
              sx={{
                color: "black",
                // fontSize: ".9rem",
                letterSpacing: "0.5px",
                // fontWeight: 500,
                textDecoration: "none",
                wordBreak: "break-all",
                // pt: 1,
                display: "block", // Ensures the box is a block element
                overflow: "hidden", // Hides the overflow text
                textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                whiteSpace: "nowrap",
                // maxWidth: "200px",
              }}
            >
              <Tooltip title={row[head.id]}>
                <Typography
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: "0.9rem",
                    letterSpacing: "0.5px",
                    // fontWeight: 600,
                    lineHeight: "1.5rem",
                    display: "inline-block", // Ensures the text behaves as an inline block
                    overflow: "hidden", // Hides the overflow text
                    textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                    whiteSpace: "nowrap", // Prevents the text from wrapping
                    verticalAlign: "bottom", //
                    maxWidth: "100px",
                  }}
                >
                  {row[head.id]}
                </Typography>
              </Tooltip>
            </Box>
            // <span>{row[head.id]}</span>
          )}
        </>
      );
    case "organization.organization_name":
      return (
        <>
          {isCompanyLink && row?.status !== "archived" ? (
            <span
              onClick={() => {
                actionFunction &&
                  actionFunction(row.id, "organization.organization_name", i);
              }}
              style={{
                color: theme.palette.primary.iconOrange,
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              {row[head.id]}
            </span>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "employer_id.organization_name":
      return (
        <>
          {isCompanyLink && row?.status !== "archived" ? (
            <span
              onClick={() => {
                actionFunction &&
                  actionFunction(row.id, "employer_id.organization_name", i);
              }}
              style={{
                color: theme.palette.primary.iconOrange,
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              {row[head.id]}
            </span>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "organization_name":
      return (
        <>
          {isCompanyLink && row?.status !== "archived" ? (
            <span
              onClick={() => {
                actionFunction &&
                  actionFunction(row.id, "organization_name", i);
              }}
              style={{
                color: theme.palette.primary.iconOrange,
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              {row[head.id]}
            </span>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "email":
      return (
        <>
          <Box
            component={"a"}
            sx={{
              color: "black",
              // fontSize: ".9rem",
              letterSpacing: "0.5px",
              // fontWeight: 500,
              textDecoration: "none",
              wordBreak: "break-all",
              // pt: 1,
              display: "block", // Ensures the box is a block element
              overflow: "hidden", // Hides the overflow text
              textOverflow: "ellipsis", // Applies ellipsis to the overflow text
              whiteSpace: "nowrap",
              // maxWidth: "200px",
            }}
          >
            <Tooltip title={row[head.id]}>
              <Typography
                sx={{
                  color: theme.palette.grey[800],
                  fontSize: "0.9rem",
                  letterSpacing: "0.5px",
                  // fontWeight: 600,
                  lineHeight: "1.5rem",
                  display: "inline-block", // Ensures the text behaves as an inline block
                  overflow: "hidden", // Hides the overflow text
                  textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                  whiteSpace: "nowrap", // Prevents the text from wrapping
                  verticalAlign: "bottom", //
                  maxWidth: "100px",
                }}
              >
                {row[head.id]}
              </Typography>
            </Tooltip>
          </Box>
          {/* <span>{row[head.id]}</span> */}
        </>
      );
    case "message":
      return (
        <>
          <Box
            component={"a"}
            sx={{
              color: "black",
              // fontSize: ".9rem",
              letterSpacing: "0.5px",
              // fontWeight: 500,
              textDecoration: "none",
              wordBreak: "break-all",
              // pt: 1,
              display: "block", // Ensures the box is a block element
              overflow: "hidden", // Hides the overflow text
              textOverflow: "ellipsis", // Applies ellipsis to the overflow text
              whiteSpace: "nowrap",
              // maxWidth: "200px",
            }}
          >
            <Tooltip title={row[head.id]}>
              <Typography
                sx={{
                  color: theme.palette.grey[800],
                  fontSize: "0.9rem",
                  letterSpacing: "0.5px",
                  // fontWeight: 600,
                  lineHeight: "1.5rem",
                  display: "inline-block", // Ensures the text behaves as an inline block
                  overflow: "hidden", // Hides the overflow text
                  textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                  whiteSpace: "nowrap", // Prevents the text from wrapping
                  verticalAlign: "bottom", //
                  maxWidth: "100px",
                }}
              >
                {row[head.id]}
              </Typography>
            </Tooltip>
          </Box>
          {/* <span>{row[head.id]}</span> */}
        </>
      );
    case "reporter.user.first_name":
      return (
        <Tooltip
          title={row?.reporter_type === "Candidate" ? "Candidate" : "Recruiter"}
        >
          <span
            onClick={() => {
              actionFunction &&
                actionFunction(row.id, "reporter.user.first_name", i);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            {row?.reporter_type === "Candidate" ? (
              <Box
                sx={{
                  width: "2px",
                  height: "20px",
                  background: theme.palette.primary.textSuccess,
                }}
              ></Box>
            ) : (
              <Box
                sx={{
                  width: "2px",
                  height: "20px",
                  background: theme.palette.primary.textError,
                }}
              ></Box>
            )}
            {row[head.id]}
          </span>
        </Tooltip>
      );
    case "reported.user.first_name":
      return (
        <Tooltip
          title={row?.reported_type === "Candidate" ? "Candidate" : "Recruiter"}
        >
          <span
            onClick={() => {
              actionFunction &&
                actionFunction(row.id, "reported.user.first_name", i);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            {row?.reported_type === "Candidate" ? (
              <Box
                sx={{
                  width: "2px",
                  height: "20px",
                  background: theme.palette.primary.textSuccess,
                }}
              ></Box>
            ) : (
              <Box
                sx={{
                  width: "2px",
                  height: "20px",
                  background: theme.palette.primary.textError,
                }}
              ></Box>
            )}
            {row[head.id]}
          </span>
        </Tooltip>
      );
    case "reason":
      return (
        <span
          onClick={() => {
            actionFunction &&
              actionFunction(row.id, "organization.organization_name", i);
          }}
          style={{
            flex: 1,
            minWidth: 0,
            marginBottom: "10px",
            textAlign: "start",
            wordBreak: "break-word", // ensures text wraps properly
          }}
        >
          {row[head.id]}
        </span>
      );
    default:
      return row[head.id];
  }
};

const isEqual = (prev: TableCellType, next: TableCellType) => {
  // if(prev.i)
  return true;
};

const TableCellMemo = React.memo(EnhancedTableCell, isEqual);
export default function EnhancedTable(props: EnhancedTableTypes) {
  const {
    page = 0,
    rowsPerPage = 10,
    handleChangePage,
    handleChangeRowsPerPage = () => {},
    headCells = [],
    handleRequestSort = () => {},
    rows = [],
    order,
    orderBy,
    isSelectable,
    setSelected,
    selected = [],
    total = 0,
    actionFunction,
    resendMail,
    isFirstNameLink = false,
    isUserLink,
    isAudit,
    isCompanyLink = false,
    isEdit = false,
    isView = false,
    isreInvite = false,
    isKyc = false,
    isDelete = false,
    isActionButton = false,
    isMoreVert = false,
    isMail = false,
    isLoading = false,
    isPagination = true,
    isNoRecordFound = true,
    Openmodal,
    setActive,
    redoKyc,
    reInvite,
    isSingleCheck,
    maxHeightValue,
    isCheckedRound = false,
    width,
    isExport,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "#28389106",
    // },
    // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
    ".MuiTableCell-root": {
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D9D9D9",
    },
  }));
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected && setSelected(newSelected);
      return;
    }
    setSelected && setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    name: string
  ) => {
    if (!isSingleCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: any = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected && setSelected(newSelected);
    } else {
      const value = name !== selected ? name : "";
      setSelected && setSelected(value);
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0;

  const visibleRows = React.useMemo(() => rows, [rows]);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    const tooltip = document.querySelector("[role='tooltip']") as HTMLElement;
    if (tooltip) {
      tooltip.style.display = "none";
    }
  };

  React.useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);

      // Clean up
      return () => {
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return (
    <Box sx={{ width: "100%", padding: "0px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          className="table-container"
          sx={{
            maxHeight: maxHeightValue
              ? maxHeightValue
              : matchDownMD
              ? "calc(100vh - 400px)"
              : "calc(100vh - 330px)",
          }}
          ref={tableContainerRef}
        >
          <Table
            stickyHeader
            sx={{ minWidth: width ? width : 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            className="inner-table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order || "asc"}
              orderBy={orderBy || ""}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length ?? 0}
              headCells={headCells}
              isSingleCheck={isSingleCheck}
              isSelectable={isSelectable || false}
            />
            <TableBody>
              {visibleRows.map((row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    onClick={(event) =>
                      isSelectable
                        ? handleClick(event, row.id)
                        : console.log("row.id", row.id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "default", border: "1" }}
                    // onMouseEnter={() => setMouseover(true)}
                    // onMouseLeave={() => setMouseover(false)}
                  >
                    {isSelectable && (
                      <TableCell padding="checkbox" sx={{ border: "1" }}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          icon={
                            <CircleOutlinedIcon sx={{ fontSize: "1.6rem" }} />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fontSize: "1.6rem" }} />
                          }
                        />
                      </TableCell>
                    )}
                    {headCells.map((head: any, i: number) => (
                      <TableCell
                        key={`table-cell-td-${i}${row.id}`}
                        align="left"
                        sx={{ border: "1" }}
                      >
                        <TableCellMemo
                          row={row}
                          head={head}
                          i={i}
                          isMail={isMail}
                          resendMail={resendMail}
                          actionFunction={actionFunction}
                          isFirstNameLink={isFirstNameLink}
                          isUserLink={isUserLink}
                          isAudit={isAudit}
                          isCompanyLink={isCompanyLink}
                          isEdit={isEdit}
                          isView={isView}
                          isreInvite={isreInvite}
                          isKyc={isKyc}
                          isDelete={isDelete}
                          isMoreVert={isMoreVert}
                          isActionButton={isActionButton}
                          Openmodal={Openmodal}
                          setActive={setActive}
                          redoKyc={redoKyc}
                          reInvite={reInvite}
                          isExport={isExport}
                        />
                      </TableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
              {!visibleRows?.length && !isLoading && isNoRecordFound && (
                <TableRow>
                  <TableCell
                    colSpan={
                      isSelectable ? headCells.length + 1 : headCells.length
                    }
                    align="center"
                  >
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isPagination && (
          <PaginationComponent
            onChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            rowsPerPageOptions={[5, 10, 25]}
          />
        )}
      </Paper>
    </Box>
  );
}
