import EmployerProfile from "pages/employer-profile";
import CandidateEnquiry from "pages/enquiryManagement/add/candidate-enquiry";
import EmployerEnquiry from "pages/enquiryManagement/add/employer-enquiry";
import SubscriptionEnquiry from "pages/enquiryManagement/add/subscription-enquiry";
import Enquiry from "pages/enquiryManagement/view/view-enquiry";
import Payment from "pages/payments";
import Profile from "pages/profile";
import UpdateProfile from "pages/profile/profile";
import Subscription from "pages/subscription";
import AddPlan from "pages/subscription/add";
import SubscriptionPlan from "pages/subscription/view";

import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";

/* guest routes */
const PageNotFound = lazy(() => import("pages/pagenotfound"));
const Dashboard = lazy(() => import("pages/dashboard"));
const CandidateManagement = lazy(() => import("pages/candidateManagement"));
const EmployerManagement = lazy(() => import("pages/employerManagement"));
const AddEmployer = lazy(
  () => import("pages/employerManagement/addEmployer/AddEmployer")
);
const ViewEmployer = lazy(
  () => import("pages/employerManagement/viewEmployer/ViewEmployer")
);
const AddMember = lazy(
  () => import("pages/employerManagement/viewEmployer/members/Addmembers")
);
const AddCandidate = lazy(
  () => import("pages/candidateManagement/addcandidate/AddCandidate")
);
const ViewCandidate = lazy(
  () => import("pages/candidateManagement/viewcandidate/ViewCandidate")
);
const BulkUpload = lazy(() => import("pages/candidateManagement/BulkUpload"));
const MemberBulkUpload = lazy(
  () => import("pages/employerManagement/viewEmployer/members/BulkUpload")
);
const JobBulkUpload = lazy(() => import("pages/jobManagement/BulkUpload"));
const Login = lazy(() => import("pages/authentication/Login"));

/* layout component */
const Layout = lazy(() => import("../layout/layout/MainLayout"));

const JobManagement = lazy(() => import("../pages/jobManagement/index"));
const AddJob = lazy(() => import("../pages/jobManagement/addjob/AddJob"));
const PreviewJob = lazy(() => import("../pages/jobManagement/Previewjob"));
const ViewJob = lazy(() => import("../pages/jobManagement/viewjob"));

const UserManagement = lazy(() => import("../pages/usermanagement/index"));
const AddUser = lazy(() => import("../pages/usermanagement/adduser/AddUser"));
const ViewUser = lazy(() => import("../pages/usermanagement/viewuser"));

const AuditTrail = lazy(() => import("../pages/auditTrail/AuditTrail"));
const Feedback = lazy(() => import("../pages/feedback"));

const EnquiryManagement = lazy(() => import("../pages/enquiryManagement"));
const ReportedChat = lazy(() => import("../pages/ReportedChats"));

// Pages

//Guest role authentication
const GuestRoute = () => {
  const auth = useAppSelector((state) => state.user.user);
  let location = useLocation();
  return Object.keys(auth).length > 0 ? (
    <Navigate to={`/dashboard`} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

const ProtectedUserRoute = () => {
  let auth = useAppSelector((state) => state.user.user);
  let location = useLocation();
  return Object.keys(auth).length > 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="not-found" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          {/* Guest routes */}
          <Route element={<GuestRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<ProtectedUserRoute />}>
            <Route element={<Layout />}>
              <Route path={`/dashboard`} element={<Dashboard />} />
              <Route
                path={`/candidate_management`}
                element={<CandidateManagement />}
              />
              <Route path={`/add_candidate`} element={<AddCandidate />} />
              <Route path={`/edit_candidate`} element={<AddCandidate />} />
              <Route path={`/view_candidate`} element={<ViewCandidate />} />
              <Route path={`/bulk_upload`} element={<BulkUpload />} />

              <Route
                path={`/employer_management`}
                element={<EmployerManagement />}
              />

              <Route path={`/employerprofile`} element={<EmployerProfile />} />

              <Route path={`/add_employer`} element={<AddEmployer />} />
              <Route path={`/edit_employer`} element={<AddEmployer />} />
              <Route path={`/view_employer`} element={<ViewEmployer />} />
              <Route path={`/add_member`} element={<AddMember />} />
              <Route
                path={`/member_bulk_upload`}
                element={<MemberBulkUpload />}
              />
              <Route path={`/job_management`} element={<JobManagement />} />
              <Route path={`/add_job`} element={<AddJob />} />
              <Route path={`/edit_job`} element={<AddJob />} />
              <Route path={`/preview_job`} element={<PreviewJob />} />
              <Route path={`/view_job`} element={<ViewJob />} />
              <Route path={`/job_bulk_upload`} element={<JobBulkUpload />} />

              <Route path={`/user_management`} element={<UserManagement />} />
              <Route path={`/add_user`} element={<AddUser />} />
              <Route path={`/edit_user`} element={<AddUser />} />
              <Route path={`/view_user`} element={<ViewUser />} />

              <Route path={`/audit_trail`} element={<AuditTrail />} />
              <Route path={`/feedback`} element={<Feedback />} />
              <Route path={`/updateprofile`} element={<Profile />} />
              <Route path={`/profile`} element={<UpdateProfile />} />
              <Route
                path={`/enquiry_management`}
                element={<EnquiryManagement />}
              />
              <Route path={`/enquiry`} element={<Enquiry />} />
              <Route
                path={`/candidate-enquiry`}
                element={<CandidateEnquiry />}
              />
              <Route path={`/employer-enquiry`} element={<EmployerEnquiry />} />
              <Route
                path={`/subscription-enquiry`}
                element={<SubscriptionEnquiry />}
              />
              <Route path={`/payment`} element={<Payment />} />
              <Route
                path={`/subscription_management`}
                element={<Subscription />}
              />
              <Route path={`/add_plan`} element={<AddPlan />} />
              <Route path={`/view_plan`} element={<SubscriptionPlan />} />
              <Route path={`/reported_chats`} element={<ReportedChat />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutePath;
