import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Link,
  Typography,
  Card,
  CardContent,
  Box,
  Divider,
  Chip,
  FormHelperText,
} from "@mui/material";
import { ContainedButton, OutlinedButton } from "component/button";
import { useLocation } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import { enquiryValidation } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import dayjs from "dayjs";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";

const ViewEnquiry = () => {
  const [candidateDetails, setCandidateDetails] = useState<any>();
  const [remarkdata, setRemarkData] = useState<any>();
  const [loading, setIsLoading] = useState<any>();

  const location = useLocation();

  const handleDetailsChange = (event: any) => {
    if (event.target.value.length <= 500) {
      setUpdateValue("details", event.target.value);
    }
  };
  const getDetails = () => {
    try {
      setIsLoading(true);
      const Data = commonService.getServices(`/enquiry/${location?.state?.id}`);
      Data.then((res) => {
        setCandidateDetails(res?.data?.data);

        setIsLoading(false);
      }).catch((err: any) => {
        console.log(err);
        Failed(errorMessage(err, "Oops! Something Went Wrong"));
        setIsLoading(false);
      });
    } catch (err) {
      console.log(err);

      Failed(errorMessage(err, "Oops! Something Went Wrong"));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      getDetails();
    }
  }, []);
  const enquiryValues = {
    name: null,
    query_type: null,
    mobile_number: null,
    email: null,
    details: null,
    status: null,
  };
  const handleSubmitEnquiry = () => {};
  const { values, errors, handleSubmit, setUpdateValue, setPatchValue } =
    useForm(enquiryValues, handleSubmitEnquiry, enquiryValidation);

  const handleCloseAndSend = () => {
    setIsLoading(true);
    const formData = {
      status: "closed",
      remark: values?.details,
    };
    if (values?.details === null) {
      Failed("Remarks should not be empty");
      setIsLoading(false);
    } else {
      commonService
        .putService(`/enquiry/${location?.state?.id}/remark`, formData)
        .then((res) => {
          setRemarkData(res?.data?.data);
          getDetails();
          setUpdateValue("details", "");

          setIsLoading(false);

          Success(res.data.message);
        })
        .catch((error: any) => {
          console.error("Error in handleCloseAndSend:", error);
          setIsLoading(false);
          Failed(errorMessage(error, "Oops! Something Went Wrong"));
        });
    }
  };

  const handleSend = () => {
    setIsLoading(true);

    const formData = {
      remark: values?.details,
    };
    if (values?.details === null) {
      Failed("Remarks should not be empty");
      setIsLoading(false);
    } else {
      commonService
        .putService(`/enquiry/${location?.state?.id}/remark`, formData)
        .then((res) => {
          setRemarkData(res?.data?.data);
          getDetails();
          setUpdateValue("details", "");
          Success(res.data.message);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          Failed(errorMessage(error, "Oops! Something Went Wrong"));
          setIsLoading(false);
        });
    }
  };
  const convertToIST = (utcDateString: any) => {
    // Parse the UTC date string to a Date object
    const date = new Date(utcDateString);

    return dayjs(date)?.format(`HH:mm`);
  };
  return (
    <>
      {loading && <Loader />}
      <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "1% 1.5%" }}>
        <Link underline="hover" color="inherit" href="/enquiry_management">
          Enquiry Management
        </Link>
        <Typography color="text.primary">
          {candidateDetails?.name
            ? candidateDetails?.name
            : candidateDetails?.organization_name
            ? candidateDetails?.organization_name
            : "N/A"}
        </Typography>
      </Breadcrumbs>

      <Card sx={{ margin: "1% 1.5%", boxShadow: 3 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "0.5rem",
            }}
          >
            <Typography fontWeight={400} fontSize={"16px"} color={"#000000"}>
              <strong>From:</strong>{" "}
              {candidateDetails?.name ? candidateDetails.name : "N/A"}
            </Typography>
            <Typography fontWeight={400} fontSize={"16px"} color={"#000000"}>
              <strong>Query Type:</strong>{" "}
              {candidateDetails?.enquiry_type?.value
                ? candidateDetails.enquiry_type.value
                : "N/A"}
            </Typography>

            <Typography sx={{ marginRight: "1rem" }}>
              <strong>Mobile No.:</strong>
              <span style={{ color: "#1656D1", marginLeft: "5px" }}>
                {candidateDetails?.mobile_number
                  ? candidateDetails.mobile_number
                  : "N/A"}
              </span>
            </Typography>
            <Typography sx={{ marginRight: "1rem" }}>
              <strong>Email ID:</strong>
              <span style={{ color: "#1656D1", marginLeft: "5px" }}>
                {candidateDetails?.email ? candidateDetails.email : "N/A"}
              </span>
            </Typography>
            {candidateDetails?.status && (
              <Chip
                label={
                  candidateDetails?.status ? candidateDetails.status : "N/A"
                }
              />
            )}
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography
            fontWeight={600}
            fontSize={"16px"}
            color={"#000000"}
            marginTop={3}
          >
            {candidateDetails?.message}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {candidateDetails?.documents?.map((item: any) => (
              <Link href={item?.document} target="_blank">
                <img src={item?.document} alt="photo" width="120px" />
              </Link>
            ))}
          </Box>

          {candidateDetails?.remark?.length > 0 && (
            <Box
              sx={{
                boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
                p: 3,
                mt: 3,
              }}
            >
              <Typography sx={{ fontWeight: "600", fontSize: "17px", mt: 2 }}>
                Remarks
              </Typography>

              <Box sx={{ mt: 3, maxHeight: "20vh", overflowY: "scroll" }}>
                {candidateDetails?.remark?.length > 0 ? (
                  candidateDetails?.remark?.map((item: any, index: number) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "90%",
                        }}
                      >
                        <Box sx={{ minWidth: "20%" }}>
                          <Typography>
                            <Typography sx={{ mt: 1 }}>
                              <span style={{ fontSize: "14px" }}>
                                {item?.created_by?.name
                                  ? item?.created_by?.name
                                  : "N/A"}
                              </span>
                            </Typography>
                            <span style={{ fontSize: "13px" }}>
                              {item?.created_at
                                ? dayjs(item?.created_at)?.format(`YYYY/MM/DD`)
                                : "N/A"}{" "}
                              ,{" "}
                              {item?.created_at
                                ? convertToIST(item?.created_at)
                                : "N/A"}
                            </span>
                          </Typography>
                        </Box>
                        <Typography
                          key={index}
                          sx={{
                            flex: 1,
                            minWidth: 0,
                            marginBottom: "10px",
                            textAlign: "start",
                            wordBreak: "break-word", // ensures text wraps properly
                            ml: "30%",
                          }}
                        >
                          {item?.remark}
                        </Typography>
                      </Box>
                      <Divider sx={{ my: 2, opacity: "0.5" }} />
                    </>
                  ))
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )}

          {!remarkdata?.remark && candidateDetails?.status !== "closed" && (
            <Typography
              fontWeight={400}
              fontSize={"14px"}
              color={"#6B778C"}
              marginBottom={1}
              marginTop={4}
            >
              Enter Remark
            </Typography>
          )}

          {candidateDetails?.status !== "closed" ? (
            <Box sx={{ mt: 3 }}>
              <TextareaAutosize
                id="details"
                name="details"
                value={values?.details ? values?.details : ""}
                onChange={handleDetailsChange}
                placeholder=""
                minRows={6}
                maxRows={6}
                style={{
                  width: "100%",
                  fontFamily: "InterVariableFont",
                  resize: "none",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              />
              <Box
                sx={{
                  textAlign: "left",
                  marginTop: "6px",
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "#6B778C",
                }}
              >
                {values?.details?.length ? values?.details?.length : 0}/500
              </Box>
              {errors?.details && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                  sx={{ mt: 0.3, p: 0 }}
                >
                  {errors?.details}
                </FormHelperText>
              )}
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ mt: "5%" }}>
            {candidateDetails?.status === "closed" ? (
              <>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: 3,
                    gap: 1,
                  }}
                >
                  <strong>Closed By:</strong>
                  {candidateDetails?.updated_by?.name
                    ? candidateDetails?.updated_by?.name
                    : "N/A"}
                  <strong style={{ marginLeft: "20px" }}>Closed on:</strong>
                  {candidateDetails?.updated_at
                    ? dayjs(candidateDetails?.updated_at).format(`YYYY/MM/DD`)
                    : "N/A"}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <OutlinedButton
                  size={"small"}
                  label={"Close & Send"}
                  isBold={true}
                  handleClick={handleCloseAndSend}
                />
                <Box sx={{ mx: 1 }} />
                <ContainedButton
                  size="large"
                  isBold={true}
                  label={"Send"}
                  // type="submit"
                  handleClick={handleSend}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ViewEnquiry;
