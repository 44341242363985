import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { OutlinedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { SocialValidationSchema } from "forms/hooks/validateRules";
import React from "react";

const SocialMedia = ({ socialMediaEditing, socialMediaClose }: any) => {
  const 
  handleUpdateLogin = (values: any) => {
    console.log("Form data submitted: ", values);
  };

  const socialMediaValues = {
    linkedin: null,
    facebook: null,
    instagram: null,
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    socialMediaValues,
    handleUpdateLogin,
    SocialValidationSchema
  );

  return (
    <Dialog
      open={socialMediaEditing}
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, fontSize: "20px", color: "#000000" }}>
        Edit Social Media Link
      </DialogTitle>
      <Divider sx={{ mb: 1, borderColor: "#000000", width: "100%" }} />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <InputField
            fieldName="Linkedin"
            values={values.linkedin}
            name="linkedin"
            errors={errors.linkedin}
            handleChange={handleChange}
            isRequired={false}
            id="linkedin"
          />
          <InputField
            fieldName="Facebook"
            values={values.facebook}
            name="facebook"
            errors={errors.facebook}
            handleChange={handleChange}
            isRequired={false}
            id="facebook"
          />
          <InputField
            fieldName="Instagram"
            values={values.instagram}
            name="instagram"
            errors={errors.instagram}
            handleChange={handleChange}
            isRequired={false}
            id="instagram"
          />
          <DialogActions sx={{ mt: 2 }}>
            <OutlinedButton
              size={"small"}
              label={"cancel"}
              isBold={true}
              handleClick={socialMediaClose}
            ></OutlinedButton>
            <Button
              sx={{ fontWeight: 600, fontSize: "18px", borderRadius: "48px" }}
              type="submit"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SocialMedia;
