import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import LinkedIn from "asset/images/images/linkedin.svg";
import FaceBook from "asset/images/images/facebook.svg";
import Instagram from "asset/images/images/instagram.svg";
import EditIcon1 from "asset/images/images/edit-image-1.svg";
import { InputField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { PersonalValidationSchema } from "forms/hooks/validateRules";
import SocialMedia from "./social-media";
import { OutlinedButton } from "component/button";

const BasicInfo = () => {
  const [basicInfoEditing, setBasicInfoEditing] = useState(false);
  const [socialMediaEditing, setSocialMediaEditing] = useState(false);

  const handleEdit = () => {
    setBasicInfoEditing(true);
  };

  const handleClose = () => {
    setBasicInfoEditing(false);
  };
  const socialMediaEdit = () => {
    setSocialMediaEditing(true);
  };
  const socialMediaClose = () => {
    setSocialMediaEditing(false);
  };

  const handleUpdateLogin = () => {
    console.log("data");
  };

  const allCategories = [
    {
      name: "100-200",
      value: "1",
    },
    {
      name: "200-300",
      value: "2",
    },
    {
      name: "300-400",
      value: "3",
    },
    {
      name: "400-500",
      value: "3",
    },
  ];

  const headOffice = [
    {
      name: "Coimbatore",
      value: "1",
    },
    {
      name: "Bengaluru",
      value: "2",
    },
    {
      name: "Chennai",
      value: "3",
    },
    {
      name: "Hyderabad",
      value: "3",
    },
  ];

  const initialLoginValues = {
    first_name: "",
    last_name: "",
    phone: "",
    organisation_size: "",
    head_office_location: "",
    organisation_address: "",
    organisation_email_id: "",
  };

  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(initialLoginValues, handleUpdateLogin, PersonalValidationSchema);

  return (
    <>
      
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={8} sx={{ display: "flex" }}>
          <Card
            sx={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px", color: "#15171E" }}
                >
                  Basic Info
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#AB5B0B",
                  }}
                  onClick={handleEdit}
                  style={{ cursor: "pointer" }}
                >
                  <img src={EditIcon1} alt="edit" />
                  <Typography sx={{ pl: 1, fontWeight: 600, fontSize: "14px" }}>
                    Edit
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />

              <Grid container spacing={2}>
                <Grid item xs={4} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#616B73"}
                  >
                    First name
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#15171E"}
                  >
                    Kiran
                  </Typography>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#616B73"}
                  >
                    Last name
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#15171E"}
                  >
                    Kumar
                  </Typography>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#616B73"}
                  >
                    Email ID
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={500}
                    color={"#15171E"}
                  >
                    kiran@rsystems.com
                  </Typography>
                </Grid>

                <Grid item xs={4} paddingTop={0} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#616B73"}
                  >
                    Mobile number
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      fontSize={"16px"}
                      fontWeight={500}
                      marginBottom={"10px"}
                      color={"#15171E"}
                    >
                      9018203888
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        marginLeft: "15px",
                        color: "#AB5B0B",
                        cursor: "pointer",
                        mb: 2,
                      }}
                      onClick={() => console.log("Verified")}
                    >
                      Verify
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#616B73"}
                  >
                    Organisation size
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#15171E"}
                  >
                    200 - 300
                  </Typography>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#616B73"}
                  >
                    Head office location
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#15171E"}
                  >
                    Bengaluru
                  </Typography>
                </Grid>

                <Grid item xs={4} lg={4}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                  >
                    Organisation pincode
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={500}
                    marginBottom={"10px"}
                    color={"#15171E"}
                  >
                    560054
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Dialog open={basicInfoEditing}>
          <DialogTitle
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: " #000000",
            }}
          >
            Edit Basic Info
          </DialogTitle>
          <Divider sx={{ mb: 1, borderColor: "#000000", width: "100%" }} />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputField
                  fieldName="First name"
                  values={values.first_name}
                  name="first_name"
                  errors={errors.first_name}
                  handleChange={handleChange}
                  isRequired={false}
                  id="first_name"
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fieldName="Last name"
                  values={values.last_name}
                  name="last_name"
                  errors={errors.last_name}
                  handleChange={handleChange}
                  isRequired={false}
                  id="last_name"
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fieldName="Organisation Email ID"
                  values={values.organisation_email_id}
                  name="organisation_email_id"
                  errors={errors.organisation_email_id}
                  handleChange={handleChange}
                  isRequired={false}
                  id="organisation_email_id"
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fieldName="Mobile number"
                  values={values.phone}
                  name="phone"
                  errors={errors.phone}
                  handleChange={handleChange}
                  isRequired={false}
                  id="phone"
                />
              </Grid>

              <Grid item xs={6}>
                <SelectField
                  fieldName="Organisation Size"
                  values={values.organisation_size}
                  name="organisation_size"
                  errors={errors.organisation_size}
                  handleChange={handleChange}
                  options={allCategories}
                  placeholder="select"
                  id="organisation_size"
                  isRequired={false}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  fieldName="Head Office Location"
                  values={values.head_office_location}
                  name="head_office_location"
                  errors={errors.head_office_location}
                  handleChange={handleChange}
                  options={headOffice}
                  placeholder="select"
                  id="head_office_location"
                  isRequired={false}
                />
              </Grid>
            </Grid>
            <InputField
              fieldName="Organisation Address"
              values={values.organisation_address}
              name="organisation_address"
              errors={errors.organisation_address}
              handleChange={handleChange}
              isRequired={false}
              id="organisation_address"
            />
          </DialogContent>
          <DialogActions sx={{ mb: 2 }}>
            <OutlinedButton
              size={"small"}
              label={"cancel"}
              isBold={true}
              handleClick={handleClose}
            ></OutlinedButton>
            <Button
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                borderRadius: "48px",
                marginRight: 2,
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Card
            sx={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px", color: "#15171E" }}
                >
                  Social Media Links
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#AB5B0B",
                  }}
                  onClick={socialMediaEdit}
                  style={{ cursor: "pointer" }}
                >
                  <img src={EditIcon1} alt="edit" />
                  <Typography sx={{ pl: 1, fontWeight: 600, fontSize: "14px" }}>
                    Edit
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />

              <CardContent sx={{ padding: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={LinkedIn}
                    alt="LinkedIn"
                    style={{ marginRight: "10px" }}
                  />
                  <Box>
                    <Typography fontWeight={500} fontSize={"14px"} color={"#616B73"}>Linkedin</Typography>
                  </Box>
                </Box>

                <a
                  style={{
                    textDecoration: "none",
                    color: "#2F80ED",
                  }}
                >
                  <Typography>
                    https://www.linkedin.com/company/r-systems/
                  </Typography>
                </a>
              </CardContent>

              <CardContent sx={{ padding: 0, mt: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={FaceBook}
                    alt="Facebook"
                    style={{ marginRight: "10px" }}
                  />
                  <Box>
                    <Typography fontWeight={500} fontSize={"14px"} color={"#616B73"}>Facebook</Typography>
                  </Box>
                </Box>
                <a style={{ textDecoration: "none", color: " #2F80ED" }}>
                  <Typography>https://www.facebook.com/rsystems</Typography>
                </a>
              </CardContent>
              <CardContent sx={{ padding: 0, mt: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={Instagram}
                    alt="Instagram"
                    style={{ marginRight: "10px" }}
                  />
                  <Box>
                    <Typography fontWeight={500} fontSize={"14px"} color={"#616B73"}>Instagram</Typography>
                  </Box>
                </Box>
                <a style={{ textDecoration: "none", color: " #2F80ED" }}>
                  <Typography>
                    https://www.instagram.com/rsystems_inc/
                  </Typography>
                </a>
              </CardContent>
            </CardContent>
          </Card>
        </Grid>

        <SocialMedia
          socialMediaEditing={socialMediaEditing}
          socialMediaClose={socialMediaClose}
        />
      </Grid>
    </>
  );
};

export default BasicInfo;
