import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';

// interface HoverImagePopoverProps {
//   imageSrc: string;
// }

const HoverImagePopover = ({img, width= '300px', content}: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <span
      
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <InfoIcon style={{ color: "ffb300" , fontSize:"1rem"}}/>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        { img && <img src={img} alt="Hovered Image" style={{ width: width, height: 'auto' }} />}
        {content && <Typography sx={{ p: 2 }}>{content}</Typography>}
      </Popover>
    </span>
  );
};

export default HoverImagePopover;
