import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import ProfileImage from "asset/images/images/profile-image1.svg";
import EditIcon from "asset/images/images/edit-icon.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import Loader from "ui-component/Loader";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [profileDetails, setProfileDetails] = useState<any>();
  const userId: any = useAppSelector((state) => state?.user?.user);

  const handleClick = () => {
    navigate("/updateprofile");
  };

  const getUserDetails = () => {
    setIsLoading(true);
    commonService
      .getServices(`/admin/${userId?._id}`)
      .then((res) => {
        const userData = res?.data?.data[0];
        setProfileDetails(userData);
        setIsLoading(false);
      })
      .catch((error) => {
        Failed(errorMessage(error, "Oops! Something Went Wrong"));
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Box sx={{ padding: "1% 1.5%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="text.primary">Profile</Typography>
        </Breadcrumbs>

        <Card sx={{ marginTop: 4, boxShadow: 1 }}>
          <CardContent sx={{ padding: 0 }}>
            <Box
              sx={{
                backgroundColor: "#0302290A",
                height: 133,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1% 1.5%",
              }}
            >
              <Box position="relative">
                {userId?.photo ? (
                  <Box
                    component={"img"}
                    height={140}
                    width={140}
                    src={userId?.photo}
                    alt="Profile Image"
                    sx={{
                      marginTop: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      height: "140px",
                      width: "140px",
                      marginTop: "60px",
                      background: "grey",
                    }}
                  />
                )}
              </Box>
              <img
                src={EditIcon}
                alt="Edit Icon"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                onClick={handleClick}
              />
            </Box>
            <Box sx={{ padding: "1% 1.5%", marginTop: 6 }}>
              <Typography variant="h2" color={"#0A1F41"}>
                {profileDetails?.name}
              </Typography>
              <Divider style={{ marginTop: "20px" }} />
              <Box sx={{ marginTop: "5%" }}>
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    <Typography variant="h3" color={"#6B778C"}>
                      Mobile Number
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={"20px"}
                      color={"#0A1F41"}
                      marginTop={2}
                    >
                      {profileDetails?.country_code +
                        " " +
                        profileDetails?.mobile}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mx: 5 }} />
                  <Grid item sm={3}>
                    <Typography variant="h3" color={"#6B778C"}>
                      Email ID
                    </Typography>
                    <Tooltip title={profileDetails?.email}>
                      <Typography
                        fontWeight={600}
                        fontSize={"20px"}
                        color={"#0A1F41"}
                        marginTop={2}
                        sx={{
                          display: "inline-block", // Ensures the text behaves as an inline block
                          overflow: "hidden", // Hides the overflow text
                          textOverflow: "ellipsis", // Applies ellipsis to the overflow text
                          whiteSpace: "nowrap", // Prevents the text from wrapping
                          verticalAlign: "bottom", //
                          maxWidth: "250px",
                          cursor: "pointer",
                        }}
                      >
                        {profileDetails?.email}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Divider orientation="vertical" flexItem sx={{ mx: 5 }} />
                  <Grid item sm={3}>
                    <Typography variant="h3" color={"#6B778C"}>
                      Password
                    </Typography>
                    <Typography variant="h3" color={"#0A1F41"} marginTop={2}>
                      *******
                    </Typography>
                    {profileDetails?.password_update_date && (
                      <Typography variant="h4" color={"#6B778C"} marginTop={1}>
                        Last updated on{" "}
                        {new Date(
                          profileDetails?.password_update_date
                        ).toLocaleDateString()}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default UpdateProfile;
