
import { ConfirmationNumber } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import Swal from "sweetalert2";
import LogoutWarning from "asset/images/images/LogoutWarning.svg"

type deletealertProps = {
    onCancel?: Function,
    onConfirmed?: Function,
    title?: string,
    message?: string,
    confirmButtonText?:string,
    data?: any
}

const deleteAlert = ({ onConfirmed,title,message,confirmButtonText, data=null , onCancel }: deletealertProps) => {
    return (
        Swal.fire({
            title: `<div class="custom-title"><img src=${LogoutWarning} alt="custom icon" class="custom-icon" /> ${title}</div>`,
            html: message,
            confirmButtonText: confirmButtonText,
            cancelButtonText: "No",
            confirmButtonColor: "red",
            showCancelButton: true,
            allowOutsideClick: false,
            customClass: {
                container: 'no-padding',
                popup: 'custom-popup',
                title: 'custom-title-container',
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button' // Add a custom class for the title container to adjust its padding/margin if needed
            }
        }).then((result) => {
            if (result.isConfirmed && onConfirmed) {
                onConfirmed(data);
            } else if (result.dismiss && onCancel) {
                onCancel();
            }
        })
        
    )
}

export default deleteAlert;
