/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */
export default function themePalette(theme: any) {
  return {
    mode: theme?.customization?.navType,
    common: {
      black: theme.colors?.darkPaper,
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark,
      primary200: theme.colors?.primary200,
      primary800: theme.colors?.primary800,
      main_div: theme.colors?.main_div,
      createButtonColor: theme?.colors?.createButtonColor,
      tableHead: theme?.colors?.tableHead,
      buttonColor: theme?.colors?.buttonColor,
      primary: theme?.colors?.primary,
      iconColor: theme.iconColor,
      textDark: theme?.colors?.textDark,
      textMid: theme?.colors?.textMid,
      textLight: theme?.colors?.textLight,
      textDisable: theme?.colors?.textDisable,
      textInverse: theme?.colors?.textInverse,
      textLink: theme?.colors?.textLink,
      textInfo: theme?.colors?.textInfo,
      textWarning: theme?.colors?.textWarning,
      textError: theme?.colors?.textError,
      textSuccess: theme?.colors?.textSuccess,
      textBtn: theme?.colors?.textBtn,
      backgroundBlueLight: theme?.colors?.backgroundBlueLight,
      backgroundBlueMid: theme?.colors?.backgroundBlueMid,
      backgroundSkyBlueLight: theme?.colors?.backgroundSkyBlueLight,
      backgroundOrangeLight: theme?.colors?.backgroundOrangeLight,
      backgroundOrangeMid: theme?.colors?.backgroundOrangeMid,
      backgroundGrey: theme?.colors?.backgroundGrey,
      backgroundDarkGrey: theme?.colors?.backgroundDarkGrey,
      backgroundDarkBlue: theme?.colors?.backgroundDarkBlue,
      backgroundYellowLight: theme?.colors?.backgroundYellowLight,
      backgroundYellowMid: theme?.colors?.backgroundYellowMid,
      borderLinelight: theme?.colors?.borderLinelight,
      borderLineMid: theme?.colors?.borderLineMid,
      borderOutline: theme?.colors?.borderOutline,
      borderOutlineHover: theme?.colors?.borderOutlineHover,
      borderOutlineFocus: theme?.colors?.borderOutlineFocus,
      supportiveYellow: theme?.colors?.supportiveYellow,
      supportiveSkyBlue: theme?.colors?.supportiveSkyBlue,
      supportiveGrey: theme?.colors?.supportiveGrey,
      toasterError: theme?.colors?.toasterError,
      toasterSuccess: theme?.colors?.toasterSuccess,
      iconGreyDark: theme?.colors?.iconGreyDark,
      iconGreyLight: theme?.colors?.iconGreyLight,
      iconOrange: theme?.colors?.iconOrange,
      iconInfo: theme?.colors?.iconInfo,
      iconWarning: theme?.colors?.iconWarning,
      iconError: theme?.colors?.iconError,
      iconSuccess: theme?.colors?.iconSuccess,
      chipGrey: theme?.colors?.chipGrey,
      chipOrange: theme?.colors?.chipOrange,

      chipyellow: theme?.colors?.chipyellow,
      chipBlue: theme?.colors?.chipBlue,
      chipGreen: theme?.colors?.chipGreen,
      buttonOrange: theme?.colors?.buttonOrange,
      buttonHover: theme?.colors?.buttonHover,
      buttonDisable: theme?.colors?.buttonDisable,
    },
    secondary: {
      light: theme.colors?.secondaryLight,
      main: theme.colors?.secondaryMain,
      dark: theme.colors?.secondaryDark,
      200: theme.colors?.secondary200,
      800: theme.colors?.secondary800,
      contrastText: theme.colors?.primaryMain,
      borderColor: theme.colors?.defaultBorderColor,
    },
    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark,
    },
    orange: {
      light: theme.colors?.orangeLight,
      main: theme.colors?.orangeMain,
      dark: theme.colors?.orangeDark,
    },
    warning: {
      light: theme.colors?.warningLight,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark,
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark,
    },
    grey: {
      50: theme.colors?.grey50,
      100: theme.colors?.inputBoxBgColor,
      500: theme.darkTextSecondary,
      600: theme.heading,
      700: theme.darkTextPrimary,
      900: theme.textDark,
    },
    dark: {
      light: theme.colors?.darkTextPrimary,
      main: theme.colors?.darkLevel1,
      dark: theme.colors?.darkLevel2,
      800: theme.colors?.darkBackground,
      900: theme.colors?.darkPaper,
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      dark: theme.textDark,
      hint: theme.colors?.grey100,
      headingText: theme.colors?.headingText,
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault,
    },
  };
}
