import {
  Box,
  Breadcrumbs,
  Button,
  FormHelperText,
  Grid,
  Link,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import { InputField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { planValidation } from "forms/hooks/validateRules";
import React from "react";
import { useNavigate } from "react-router-dom";

const AddPlan = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const initialValues = {
    plan_name: null,
    paymen_type: null,
    price: null,
    hires: null,
    job_slots: null,
    featured_job_slots: null,
    search_results: null,
    cv_views: null,
    posts: null,
    details: null,
  };

  const paymentType = [
    { name: "Monthly", value: "30" },
    { name: "Quarterly", value: "90" },
    { name: "Yearly", value: "365" },
  ];

  const handleDetailsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.target.value.length <= 150) {
      setUpdateValue("details", event.target.value);
    }
  };
  const handlseSubmitPlan = () => {
    const payload = {
      name: values?.plan_name,
      price: +values?.price,
      plan_period: +values?.paymen_type,
      hires: +values?.hires,
      description: values?.details,
      job_slots: +values?.job_slots,
      job_posts: +values?.posts,
      featured_job_slots: +values?.featured_job_slots,
      search_results: +values?.search_results,
      cv_views: +values?.cv_views,
    };
    commonService
      .postService(`plan`, payload)
      .then((response) => {
        console.log("Success:", response);
        navigate(`/subscription_management`);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const { values, setUpdateValue, handleSubmit, errors, handleChange } =
    useForm(initialValues, handlseSubmitPlan, planValidation);
  return (
    <>
      <Box sx={{ padding: "0 20px" }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "1% 1.5%" }}>
          <Link
            underline="hover"
            color="inherit"
            href="/subscription_management"
          >
            Subscription Management
          </Link>
          <Typography>Add Plan</Typography>
        </Breadcrumbs>
        <Box sx={{ padding: "0 20px" }}>
          <Typography
            marginTop={2}
            fontWeight={700}
            fontSize={"20px"}
            color={"#000000"}
            marginBottom={1.5}
          >
            Plan Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <InputField
                fieldName="Plan Name"
                values={values?.plan_name || ""}
                name="plan_name"
                errors={errors?.plan_name}
                handleChange={handleChange}
                id="plan_name"
                isRequired={true}
              />
            </Grid>
            <Grid item lg={4}>
              <SelectField
                fieldName="Payment Type"
                values={values.paymen_type}
                name="paymen_type"
                errors={errors.paymen_type}
                handleChange={handleChange}
                options={paymentType}
                placeholder=""
                id="paymen_type"
                isRequired={true}
              />
            </Grid>
            <Grid item lg={4}>
              <InputField
                fieldName="Price"
                values={values?.price || ""}
                name="price"
                errors={errors?.price}
                handleChange={handleChange}
                id="price"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={0.5}>
            <Grid item lg={4}>
              <InputField
                fieldName="Hires"
                values={values?.hires || ""}
                name="hires"
                errors={errors?.hires}
                handleChange={handleChange}
                id="hires"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <InputField
                fieldName="Job Slots"
                values={values?.job_slots || ""}
                name="job_slots"
                errors={errors?.job_slots}
                handleChange={handleChange}
                id="job_slots"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <InputField
                fieldName="Job Branding"
                values={values?.featured_job_slots || ""}
                name="featured_job_slots"
                errors={errors?.featured_job_slots}
                handleChange={handleChange}
                id="featured_job_slots"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <InputField
                fieldName="Search Results"
                values={values?.search_results || ""}
                name="search_results"
                errors={errors?.search_results}
                handleChange={handleChange}
                id="search_results"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <InputField
                fieldName="CV Views"
                values={values?.cv_views || ""}
                name="cv_views"
                errors={errors?.cv_views}
                handleChange={handleChange}
                id="cv_views"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <InputField
                fieldName="Posts"
                values={values?.posts || ""}
                name="posts"
                errors={errors?.posts}
                handleChange={handleChange}
                id="posts"
                isRequired={true}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>
            <Grid item lg={8}>
              <Typography
                fontWeight={500}
                fontSize={"14px"}
                marginTop={2}
                marginBottom={1}
              >
                Description
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <TextareaAutosize
                  id="details"
                  name="details"
                  value={values?.details || ""}
                  onChange={handleDetailsChange}
                  placeholder=""
                  minRows={4}
                  maxRows={4}
                  style={{
                    width: "100%",
                    fontFamily: "InterVariableFont",
                    resize: "none",
                    fontWeight: 400,
                    fontSize: "16px",
                    border: errors?.details ? "1px solid red" : "",
                    borderRadius: "5px",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "right",
                    marginTop: "6px",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: errors?.details ? " red" : "#6B778C",
                  }}
                >
                  {values?.details?.length || 0}/150
                </Box>
                {errors?.details && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                    sx={{ mt: 0.3, p: 0 }}
                  >
                    {errors?.details}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "27vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: 2,
            mt: 2,
            boxShadow: "0px -4px 8px 0px #33414D1A",
            alignItems: "center",
            height: "80px",
            marginTop: "auto",
          }}
        >
          <Button
            sx={{
              padding: "10px 40px",
              background: "transparent",
              border: `1px solid ${theme.palette.primary.buttonOrange}`,
            }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            sx={{ padding: "10px 40px", mr: 2 }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddPlan;
