import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { OutlinedButton } from "component/button";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { spotilightsValidationSchema } from "forms/hooks/validateRules";
import CloseIcon from "@mui/icons-material/Close";

const Spotlight = ({ openSpotlightsDialog, handleCloseSpotlights }: any) => {
  const handleUpdateLogin = (values: any) => {
    console.log("Form data submitted: ", values);
  };

  const spotlightValues = {
    video_link: null,
    heading: null,
    description: null,
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    spotlightValues,
    handleUpdateLogin,
    spotilightsValidationSchema
  );

  return (
    <Dialog open={openSpotlightsDialog} maxWidth="sm" fullWidth>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: "24px",
              color: "#15171E",
              flexGrow: 1,
            }}
          >
            Edit Spotlight
          </DialogTitle>
          <IconButton
            onClick={handleCloseSpotlights}
            aria-label="close"
            sx={{ color: "#AB5B0B" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent>
          <InputField
            fieldName="Video Link (Youtube)"
            values={values.video_link}
            name="video_link"
            errors={errors?.video_link}
            handleChange={handleChange}
            id="video_link"
            isRequired={false}
          />

          <InputField
            fieldName="Heading"
            values={values.heading}
            name="heading"
            errors={errors?.heading}
            handleChange={handleChange}
            id="heading"
            isRequired={false}
          />
          <InputField
            fieldName="Description (150 words)"
            values={values.description}
            name="description"
            errors={errors?.description}
            handleChange={handleChange}
            id="description"
            isRequired={false}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <OutlinedButton
            size={"small"}
            label={"cancel"}
            isBold={true}
            handleClick={handleCloseSpotlights}
          ></OutlinedButton>
          <Button
            sx={{
              fontWeight: 600,
              fontSize: "18px",
              borderRadius: "48px",
              marginRight: 2,
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Spotlight;
