import axios from "axios";
import { Failed } from "forms/helpers/toast.helper";
import { clearStore } from "utils/redux.utils";

const mainAxios = process.env.REACT_APP_END_URL;
const ekamToken = sessionStorage.getItem("token")
// const ssoAxios = process.env.REACT_APP_SSO_URL;

axios.defaults.baseURL = mainAxios;
export const IsBaseURL = (setCommonCode = true, URLName: any = "dw") => {
  axios.defaults.baseURL = mainAxios;
};
export const SetAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

const onRequestFulfilled = async (config: any) => {
  let token = sessionStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return Promise.resolve(config);
};

const onRequestRejected = (error: any) => {
  return Promise.reject(error);
};

const onResponseFulfilled = (config: any) => {
  return Promise.resolve(config);
};

const onResponseRejected = (error: any) => {
  if (error?.response?.status === 401) {
    sessionStorage.clear();
    clearStore();
      Failed("Session Expired")
    window.location.href = "/login";
  }
  return Promise.reject(error);
};

export const resetSession = () => {
  sessionStorage.removeItem("ekam-auth");
};
