import { Box, Pagination, TablePagination, useTheme } from "@mui/material";

interface IPagination {
  page?: number;
  onChange?: any;
  rowsPerPage?: number;
  onRowsPerPageChange?: any;
  total?: number;
  rowsPerPageOptions?: any[];
}

const PaginationComponent = (props: IPagination) => {
  console.log(props?.total);
  const theme = useTheme();
  const {
    onChange,
    onRowsPerPageChange,
    page = 1,
    rowsPerPage = 10,
    total = 0,
    rowsPerPageOptions = [10, 20, 35, 50],
  } = props;
  const handleChange = (event: any, newPage: number) => {
    onChange(event, newPage - 1);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={{ md: "space-between" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      <TablePagination
        component="div"
        nextIconButtonProps={{ style: { display: "none" } }}
        backIconButtonProps={{ style: { display: "none" } }}
        count={total}
        page={page}
        labelRowsPerPage=""
        sx={{
          ".MuiTablePagination-toolbar": {
            flexDirection: "row-reverse",
            pt: 1,
            pb: 1,
          },
        }}
        onPageChange={onChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={onRowsPerPageChange}
        labelDisplayedRows={(paginationInfo) => {
          if (paginationInfo.count === 1) {
            return `Showing ${paginationInfo.from} - ${paginationInfo.to} of ${paginationInfo.count} results`;
          }
          return `Showing ${paginationInfo.from} - ${paginationInfo.to} of ${paginationInfo.count} results`;
        }}
      />
      <Pagination
        count={Math.ceil(total / rowsPerPage)}
        page={page === 0 ? 1 : page + 1}
        onChange={handleChange}
        sx={{
          "& .MuiPaginationItem-root.Mui-selected": {
            color: theme.palette.background.paper,
            background: theme.palette.primary.iconOrange,
            "&:hover": {
              color: theme.palette.background.paper,
              background: theme.palette.primary.iconOrange,
            },
          },
        }}
      />
    </Box>
  );
};

export default PaginationComponent;
