import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import List from "./List";
import {
  AutoCompleteField,
  InputDatePicker,
  InputField,
  MultiAutoCompleteField,
} from "forms/hoc/formfield";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "asset/images/images/search.svg";
import useForm from "forms/hooks/useForm";
import { Filterchema } from "forms/hooks/validateRules";
import CloseIcon from "@mui/icons-material/Close";
import { commonService } from "forms/helpers/common.service";
import dayjs from "dayjs";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";
import { debounce } from "helpers/debounce";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import axios from "axios";
import { saveAs } from "file-saver";

const Payment = () => {
  const theme = useTheme();
  const [search, setSearch] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  let [order, setOrder] = useState<any>("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState(0);
  let [tableData, setTableData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModal, setIsmodal] = useState<any>();
  const [filterSubmit, setFilterSubmit] = useState<boolean>(false);
  const [filteractive, setFilterActive] = useState<any>(false);

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [isDownloadModal, setDownloadmodal] = useState<any>(false);
  const [plansOptions, setPlansOptions] = useState<any>();
  const [selectedDuration, setSelectedDuration] = useState<any>();
  const [statusOptions, setStatus] = useState<any>([
    // {
    //   name: "Paid",
    //   value: "paid",
    // },
    // {
    //   name: "Pending",
    //   value: "pending",
    // },
    // {
    //   name: "Unpaid",
    //   value: "unpaid",
    // },
    {
      name: "Offline",
      value: "offline",
    },
  ]);
  const [duration, setDuration] = useState<any>([
    {
      name: "Custom Date",
      value: "custom date",
    },
    {
      name: "Last one week",
      value: "last one week",
    },
    {
      name: "Last one month",
      value: "last one month",
    },
    {
      name: "Last three months",
      value: "last three months",
    },
  ]);

  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const Downloadstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matchDownMD ? 300 : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  const calculateDateRange = (durationValue: any) => {
    let from = dayjs();
    let to = dayjs();

    switch (durationValue) {
      case "last one week":
        from = from.subtract(1, "week");
        break;
      case "last one month":
        from = from.subtract(1, "month");
        break;
      case "last three months":
        from = from.subtract(3, "months");
        break;
      default:
        return { from: null, to: null };
    }

    return {
      from: from?.format("YYYY-MM-DD"),
      to: to?.format("YYYY-MM-DD"),
    };
  };
  const [dateRange, setDateRange] = useState(calculateDateRange("custom date"));

  useEffect(() => {
    if (selectedDuration?.value !== "custom date") {
      setDateRange(calculateDateRange(selectedDuration?.value));
    }
  }, [selectedDuration]);

  const handleDateChange = (name: any, date: any) => {
    const newDateRange = { ...dateRange, [name]: date?.format("YYYY-MM-DD") };
    setDateRange(newDateRange);
    setSelectedDuration({
      name: "Custom Date",
      value: "custom date",
    });
  };
  const InitialValue = {
    plan_id: [],
    status: [],
    end_date: null,
    start_date: null,
    from: null,
    to: null,
  };
  const getPlans = () => {
    commonService
      .getServices(`/plan?is_active=true`)
      .then((res) => {
        const planData = res?.data?.data?.results?.map((res: any) => {
          return {
            name: res?.name,
            value: res?._id,
          };
        });
        setPlansOptions(planData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function getTableData() {
    setLoading(true);
    setFilterSubmit(false);

    const params = new URLSearchParams({
      per_page: rowsPerPage.toString(),
      page: (page + 1).toString(),
      sort_order: order,
      sort_by: orderBy,
      search: search ?? "", // Uncomment if search is needed
      search_by: "employer_id.organization_name,recruiter_id.first_name",
    }).toString();
    commonService
      .postService(`/payment/payment_history/list?${params}`, {
        plan_id: values?.plan_id
          ? values?.plan_id?.map((item: any) => item?.value)
          : [],
        status: values?.status
          ? values?.status?.map((item: any) => item?.value)
          : [],
        start_date: values?.start_date ? values?.start_date : null,
        end_date: values?.end_date ? values?.end_date : null,
      })
      .then((res: any) => {
        const data = res?.data?.data?.results.map((item: any) => {
          const Paymentdate: any = item?.payment_date
            ? dayjs(new Date(item?.payment_date)).format(`YYYY/MM/DD`)
            : null;
          const Expirydate: any = item?.expiry_date
            ? dayjs(new Date(item?.expiry_date)).format(`YYYY/MM/DD`)
            : null;
          return {
            id: item?.employer_id?._id,
            reference_id: item?.reference_id,
            "employer_id.organization_name":
              item?.employer_id?.organization_name,
            "recruiter_id.first_name":
              item?.recruiter_id?.first_name +
              " " +
              item?.recruiter_id?.last_name,
            "recruiter_id.email": item?.recruiter_id?.email,
            "plan_id.name": item?.plan_id?.name,
            subscription: item?.plan_id?.name,
            payment_date: Paymentdate,
            expiry_date: Expirydate,
            status: item?.status,
            mobile: item?.country_code + " " + item?.mobile,
          };
        });

        setTableData(data);
        setCount(res?.data?.data?.pagination?.total);
        setInitialLoad(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Failed(errorMessage(error, "Oops! Something went wrong"));
      });
  }
  /* per page row handle */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    setPage(0);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const handleSearch = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0);
    setSearch((pre: any) => (pre = e.target.value));
  };
  const handleFilter = () => {
    setOpen(false);
    setPage(0);
    setFilterSubmit(true);
  };
  const { values, setUpdateValue, handleSubmit, errors } = useForm(
    InitialValue,
    handleFilter,
    Filterchema
  );
  useEffect(() => {
    getTableData();
    getPlans();
    filterActive();
  }, [rowsPerPage, page, orderBy, order, filterSubmit, filteractive]);
  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      // Perform your search logic here
      if (initialLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };
    // if (search.length >= 3 || !search) {
    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
    // }
  }, [search]);
  const handleClearFilters = () => {
    setUpdateValue("status", []);
    setUpdateValue("plan_id", []);
    setUpdateValue("start_date", null);
    setUpdateValue("end_date", null);
  };
  const filterActive = () => {
    if (
      values?.status?.length > 0 ||
      values?.plan_id?.length > 0 ||
      values?.start_date !== null ||
      values?.end_date !== null
    ) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }
  };
  const handleClose = () => {
    setDownloadmodal(false);
  };
  const handleFromDate = (event: any) => {
    setUpdateValue("start_date", null);
    event.stopPropagation();
  };
  const handleToDate = (event: any) => {
    setUpdateValue("end_date", null);
    event.stopPropagation();
  };

  const handleDownload = async () => {
    if (dateRange?.from === null || dateRange?.to === null) {
      return Failed("Please enter the dates to continue");
    }
    try {
      const pdf = await axios({
        method: "post",
        url: `${process.env.REACT_APP_END_URL}payment/invoice-pdf`,
        data: {
          start_date: dayjs(dateRange?.from).format(`YYYY/MM/DD`),
          end_date: dayjs(dateRange?.to).format(`YYYY/MM/DD`),
        },
        responseType: "blob",
      });
      saveAs(pdf?.data, `Invoice.pdf`);
    } catch (error) {
      console.log(error);
      Failed(errorMessage(error, "Oops! Something Went Wrong"));
    }
  };
  return (
    <Box sx={{ padding: " 20px" }}>
      {isLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ minWidth: "30%" }}>
          <InputField
            placeholder="Search by Name , Contact Person"
            values={search}
            name="password"
            handleChange={handleSearch}
            id="password"
            adorament="startAdornment"
            Icon={<img src={SearchIcon} alt="search" />}
            position="start"
            type="text"
            isRequired={false}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            startIcon={
              <FilterListIcon
                sx={{ color: theme.palette.primary.iconOrange }}
              />
            }
            sx={{
              background: "transparent",
              border: `1px solid ${theme.palette.primary.borderOutline}`,
              borderRadius: "10px",
              "&:hover": {
                background: "transparent",
              },
            }}
            variant="outlined"
            onClick={toggleDrawer(true)}
          >
            Filter
            {filteractive && (
              <span
                style={{
                  lineHeight: "0",
                  fontSize: "17px",
                  position: "absolute",
                  top: "2px",
                  right: "0px",
                  color: theme.palette.primary.buttonOrange,
                }}
              >
                &#x25cf;
              </span>
            )}
          </Button>
          <Button
            onClick={() => setDownloadmodal(true)}
            startIcon={<DownloadForOfflineOutlinedIcon />}
            sx={{
              borderRadius: "10px",
            }}
          >
            Download Invoice
          </Button>
        </Box>
      </Box>
      <Box sx={{ padding: "20px 0" }}>
        {!isLoading && (
          <List
            search={search}
            setPage={setPage}
            page={page}
            setIsmodal={setIsmodal}
            rowsPerPage={rowsPerPage}
            tableData={tableData}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            isLoading={isLoading}
            count={count}
            getTableData={getTableData}
            setLoading={setLoading}
          />
        )}
      </Box>
      <Drawer open={open} anchor="right">
        <Typography
          sx={{ fontWeight: "600", padding: "10px", fontSize: "20px" }}
        >
          Filters
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            right: "2px",
            top: "1px",
          }}
          onClick={toggleDrawer(false)}
        >
          <CloseIcon
            sx={{
              fontSize: "20px",
              color: theme.palette.primary.iconOrange,
            }}
          />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {/* {filter && ( */}
          <Typography
            onClick={handleClearFilters}
            sx={{
              background: "transparent",
              padding: "0 10px",
              color: theme.palette.primary.buttonOrange,
              fontSize: "12px",
              cursor: "pointer",
              mr: "10px",
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            Clear all filters
          </Typography>
          {/* )} */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "90%",
          }}
          component={"form"}
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              width: 350,
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              height: "85%",
              overflowY: "scroll",
            }}
            role="presentation"
          >
            <MultiAutoCompleteField
              values={values?.plan_id || []}
              fieldName="Plan"
              name="plan_id"
              handleInputChange={() => {}}
              errors={errors?.plan_id}
              handleChange={(e: any) => setUpdateValue("plan_id", e)}
              options={plansOptions ? plansOptions : []}
              id="plan_id"
              size={"medium"}
              isRequired={false}
              multiple={true}
              label={"Plan"}
              limit={1}
            />
            <MultiAutoCompleteField
              values={values?.status || []}
              fieldName="Status"
              name="status"
              handleInputChange={() => {}}
              errors={errors?.status}
              handleChange={(e: any) => setUpdateValue("status", e)}
              options={statusOptions ? statusOptions : []}
              id="status"
              size={"medium"}
              isRequired={false}
              multiple={true}
              label={"Status"}
              limit={1}
            />
            <InputDatePicker
              fieldName="From"
              values={dayjs(values.start_date) || null}
              name="start_date"
              errors={errors.start_date}
              handleChange={(e) => {
                setUpdateValue("start_date", dayjs(e)?.format("YYYY-MM-DD"));
              }}
              isRequired={false}
              handleClearDate={(e: any) => handleFromDate(e)}
              isValueClearRequired={true}
            />
            <InputDatePicker
              fieldName="To"
              values={dayjs(values.end_date) || null}
              name="end_date"
              errors={errors.end_date}
              handleChange={(e) =>
                setUpdateValue("end_date", dayjs(e)?.format("YYYY-MM-DD"))
              }
              isRequired={false}
              handleClearDate={(e: any) => handleToDate(e)}
              isValueClearRequired={true}
            />
          </Box>
          <Box sx={{ display: "flex", mb: "20px", gap: "10px" }}>
            <Button
              sx={{
                padding: "10px 50px",
                background: "transparent",
                border: `1px solid ${theme.palette.primary.buttonOrange}`,
              }}
              onClick={toggleDrawer(false)}
            >
              Cancel
            </Button>
            <Button sx={{ padding: "10px 40px" }} type="submit">
              {" "}
              Apply Filter
            </Button>
          </Box>
        </Box>
      </Drawer>
      {isDownloadModal && (
        <Modal open={isDownloadModal}>
          <Box sx={Downloadstyle}>
            <Typography
              sx={{ fontWeight: "600", padding: " 10px 0", fontSize: "20px" }}
            >
              Download Invoice
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                right: "2px",
                top: "1px",
              }}
              onClick={handleClose}
            >
              <CloseIcon
                sx={{
                  fontSize: "20px",
                  color: theme.palette.primary.iconOrange,
                }}
              />
            </IconButton>

            <AutoCompleteField
              name="duration"
              fieldName="Duration"
              options={duration}
              values={selectedDuration}
              handleChange={(duration) => setSelectedDuration(duration)}
              isRequired={false}
              id="duration"
              isValueClearRequired={true}
              handleClearSelectValue={() => setSelectedDuration(null)}
            />
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", padding: "20px 0" }}
            >
              Date Range
            </Typography>
            <InputDatePicker
              fieldName="From"
              values={dayjs(dateRange.from) || null}
              name="from"
              errors={errors.from}
              handleChange={(e) => handleDateChange("from", dayjs(e))}
              isRequired={false}
              handleClearDate={(e: any) => handleDateChange("from", null)}
              isValueClearRequired={true}
            />
            <InputDatePicker
              fieldName="To"
              values={dayjs(dateRange.to) || null}
              name="to"
              errors={errors.to}
              handleChange={(e) => handleDateChange("to", dayjs(e))}
              isRequired={false}
              handleClearDate={(e: any) => handleDateChange("to", null)}
              isValueClearRequired={true}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 3,
                mt: 4,
              }}
            >
              <Button sx={{ padding: "10px 50px" }} onClick={handleDownload}>
                Submit
              </Button>
              <Button
                sx={{
                  padding: "10px 50px",
                  background: "transparent",
                  border: `1px solid ${theme.palette.primary.buttonOrange}`,
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Payment;
